/// Hide visually but still be available to screenreaders
/// @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
/// @link https://github.com/h5bp/html5-boilerplate/blob/main/dist/css/style.css
/// @author Jonathan Snook
///
/// @ignore [1] For long content, line feeds are not interpreted as spaces and small width causes content to wrap 1 word per line:
/// @ignore https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap; // [1]
  width: 1px;
}

/// Undo visually hidden styles (rarely needed)
@mixin undo-visually-hidden {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}
