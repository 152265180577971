//------------------------------------------------------------------------
// Webform: TCR AKA Tips, Complaints, and Referrals
// Some of the styles for this form are located in the SEC_webforms custom module.
//------------------------------------------------------------------------

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/* Disclaimer page */
/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
.tcr-declaimer-body .usa-button {
  margin-top: 0.75em;
}