//------------------------------------------------------------------------
// Article node custom styles
//------------------------------------------------------------------------
.node--type-secarticle {

  // spacing between fields at the top of a node
  .field--name-field-publish-date,
  .field--name-field-release-number {
    margin-bottom: $field-small-vert-margin;
  }

  // Controls if the publish date shows or hides - "Hide Publish Date on Detail Page"
  .field_publish_date {
    &__show {
      display: block;
    }
    &__hide {
      display: none;
    }
  }

  // Space between static paragraph and alert on public alerts
  .public-alerts-container > .usa-alert {
    margin-top: $field-default-vert-margin;
  }

  // Added spacing on data viz embeds on articles
  .node--embedded-viz {
    :where(.field:not(:last-child)) {
      margin-bottom: $field-small-vert-margin;
    }


  }

  // reset styles for embedded data viz with a caption
  .field--name-body {
    & > figure:not(.caption-drupal-media) {
      margin: 0;
    }

    & > .caption:not(.caption-drupal-media),
    & > .caption:not(.caption-drupal-media) > figcaption {
      display: block;
    }
  }
}
