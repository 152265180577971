//------------------------------------------------------------------------
// Icon and text component
//------------------------------------------------------------------------
blockquote {
  @include font-smoothing;
  @include scale(font-size, (375px: 18px, $layout-max-width-narrow-bp: 26px));
  @include scale(padding-bottom, (375px: 22px, $layout-max-width-narrow-bp: 4px));
  @include scale(padding-left, (375px: 12px, $layout-max-width-narrow-bp: 40px));
  border-left: 4px solid $bg-teal;
  font-family: $gotham-font-family;
  letter-spacing: rem(0.2px);
  line-height: 1.25;

  // Override ".usa-prose" paragraph styles
  p {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
    margin-top: 0;

    & + p {
      margin-top: 0.75em;
    }
  }

  cite, .author {
    color: $c-gray;
    display: block;
    font-size: rem(16px);
    line-height: 1.3;
    margin-top: rem(34px);
  }
}
