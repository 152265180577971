// Event status tag (e.g. cancelled, postponed)
// https://www.figma.com/file/sZ2xwStfidy1VqjNTHxKfx/SEC22-Design-System?type=design&node-id=7987-35697
// Note: These styles are very similar to the collection status tag
//       (see /sass/components/uswds-overrides/_collection.scss)

// 1. We can’t apply more than -7px top margin with “display: inline-block”
//    or “display: inline-flex” for some reason so we’re using float.
.event-status-tag {
  --background-color: #{$bg-gray-light};
  --color: #{$bg-gray};
  @include scale(font-size, (375px: 16px, $layout-text-scale-end-bp: 18px));
  background-color: var(--background-color);
  border: 2px solid var(--color);
  color: var(--color);
  display: inline-block;
  float: left;// [1]
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: rem(21px);
  padding: rem(4px 4px 3px);
  text-transform: uppercase;

  & + * {
    clear: both;
  }

  .block-page-title-block + & {
    margin-top: rem(-12px);
  }

  &__cancelled {
    --background-color: #{rgba($c-red-dark, 0.1)};
    --color: #{$c-red-dark};
  }

  &__postponed {
    --background-color: #{$bg-orange-light};
    --color: #{$c-orange};
  }
}
