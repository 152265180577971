//------------------------------------------------------------------------
// Drupal Dropbutton Styles
//------------------------------------------------------------------------
// TODO: clean up file
// Note: Might be smart to just remove /core/themes/seven/css/components/dropbutton.component.css and replace it instead of overwriting it
.js .dropbutton-multiple {
  $dropbutton-color: $c-black;
  $dropbutton-hover-color: $bg-gray-med-light; // quick color choice, will need to review
  $focus-outline-color: #2491ff;
  margin-bottom: 1rem;
  margin-right: -0.75rem;
  margin-top: 1.25rem;
  width: 19rem;

  &.open {
    .dropbutton-action {
      &:last-child,
      &:first-child {
        a,
        button,
        input,
        .button {
          border-radius: 0;
        }
      }
    }
  }

  .dropbutton-toggle {
    button {
      display: block;
    }

    &:hover {
      background: unset;
    }

    &:focus {
      background: unset;
      outline: 0.25rem solid $focus-outline-color;
      outline-offset: 0;
    }
  }

  .dropbutton-wrapper {
    cursor: pointer;
    width: rem(18rem);
  }

  ul {
    border-right: 1px solid #a6a6a6;
    margin-right: rem(4px);
  }

  input {
    width: 14.6rem;
  }

  .dropbutton-widget {
    background-color: #fff;
    background-image: none;
    border-radius: 0;
    position: relative;
    // margin: 0.5rem 0;

    li {
      input {
        margin-left: 0.5rem;

        &:hover {
          background-image: none;
        }

        &:focus {
          background: $dropbutton-hover-color;
          border-radius: 0;
          color: $dropbutton-color;
          outline-offset: 0.25rem;
        }
      }

      button:focus {
        padding: 0;
      }
    }

    .dropbutton-action {
      a,
      input,
      button {
        border-radius: 0;
        font-size: rem(14px);
        margin: 0;
        padding: 10px 15px;
        width: 100%;

        &:hover {
          background: $dropbutton-hover-color;
          color: $dropbutton-color;
        }

        &:focus {
          background: $dropbutton-hover-color;
          color: $dropbutton-color;
          outline: 0.25rem solid $focus-outline-color;
          outline-offset: -0.25rem;
        }
      }
    }

    .dropbutton-toggle {
      button {
        border-radius: 0;

        &:hover {
          background: $dropbutton-hover-color;
          color: $dropbutton-color;
        }

        &:focus {
          background: $dropbutton-hover-color;
          color: $dropbutton-color;
          outline-offset: 0;
          outline: 0.25rem solid $focus-outline-color;
        }
      }
    }
  }
}