// -------------------------------------------------------------------------
// Base styles (e.g. :root, html, body)
// -------------------------------------------------------------------------
:root {
  // This variable is used to set the section margin between sections
  // See /sass/functions/_scale-clamp.scss for details of how it works.
  --layout-section-vert-margin: #{scale-clamp((
    375px: $layout-section-vert-margin-min,
    960px: $layout-section-vert-margin-max
  ))};

  // This variable is used to set the default margin between components within a section
  --layout-default-vert-margin: #{scale-clamp((
    375px: $layout-default-vert-margin-min,
    960px: $layout-default-vert-margin-max
  ))};

    // This var is used to set the vertical margins for components on nodes (Content Field)
    --field-component-vert-margin: #{scale-clamp((
      375px: $field-component-vert-margin-min,
      960px: $field-component-vert-margin-max
    ))};

  // This var is used to set the vertical margins for fields on nodes etc
  --field-default-vert-margin: #{scale-clamp((
    375px: $field-default-vert-margin-min,
    960px: $field-default-vert-margin-max
  ))};

  // This var is used to set the vertical margins for fields on nodes etc when it needs to be smaller
  --field-small-vert-margin: #{scale-clamp((
    375px: $field-small-vert-margin-min,
    960px: $field-small-vert-margin-max
  ))};

  // Header height needed for jump link offset
  --sticky-header-height: #{rem(70px)};

  @include min-width($layout-header-bp-medium) {
    --sticky-header-height: #{rem(112px)};
  }

  @include min-width($layout-header-bp-wide) {
    --sticky-header-height: #{rem(131px)};
  }
}

html {
  // Support iOS accessibility text size
  // Note: Only apply on iOS due to bug with Safari 14 on Big Sur
  // https://webkit.org/blog/3709/using-the-system-font-in-web-content/
  // https://www.interactiveaccessibility.com/blog/text-resizing-web-pages-ios-using-dynamic-type
  // https://dev.to/colingourlay/how-to-support-apple-s-dynamic-text-in-your-web-content-with-css-40c0
  // https://gist.github.com/colingourlay/d95908ec5cd4854c7a5afa06f3989479
  // https://github.com/twbs/bootstrap/issues/34107
  // NOTE: The “ua-ios” class is added via inline JS in the <head> in html.html.twig
  //       We’re detecting iOS by checking the user-agent string, hence the “ua” prefix.
  &.ua-ios {
    // stylelint-disable font-family-no-missing-generic-family-keyword
    @supports (font: -apple-system-body) {
      font: -apple-system-body;
    }
  }

  // Add extra margin above jump link targets to offset the sticky header
  // https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
  // https://caniuse.com/mdn-css_properties_scroll-padding
  @supports (scroll-padding-top: 0) {
    scroll-padding-top: calc(var(--sticky-header-height) + 20px);
  }

  // Allow long words to wrap in narrow viewports
  @include max-width(319px) {
    @include break-word;
  }
}

// 2. Set “font-family” on body since we’re applying “-apple-system-body” on html element
// 3. Prevent browsers from automatically adding hyphens by default
body {
  background-color: $bg-gray-lighter;// override USWDS
  font-family: $body-copy-font;// [2]
  hyphens: manual;// [3]

  // No gray background on the homepage
  &.path-frontpage-plus {
    background-color: $bg-default !important  ;
  }

  // background in WYSIWYG
  &.cke_editable {
    background-color: $bg-default;
  }
}

// Prevent zoom on iOS when selecting form fields
// Note: The “ua-ios” class is added via inline JS in /templates/html.html.twig
// https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
// http://www.456bereastreet.com/archive/201212/ios_webkit_browsers_and_auto-zooming_form_controls/
.ua-ios {
  input,
  textarea,
  select {
    // Use max() to make it possible to override in component styles
    @supports (font-size: #{"max(1em, 16px)"}) {
      font-size: #{"max(1em, 16px)"};
    }
  }
}

// Automatically apply 16:9 aspect ratio to YouTube embeds
iframe:is([src*="www.youtube.com"], [src*="www.youtube-nocookie.com"]) {
  aspect-ratio: 16/9;
  height: auto;
}
