//
// STAFF PAPERS LIST
// VIEW NAME: staff-papers-list
// List Articles with a type of "Staff Papers"
//
// --------------------------------------------------

.staff-papers-list .display-title-extras > div {
  & > :first-child  {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
