//------------------------------------------------------------------------
// Default layout styles
//------------------------------------------------------------------------
// This class is applied to the <main> element on all pages
.main-content {
  $selector: &;
  background-color: $bg-default;
  margin-left: auto;
  margin-right: auto;
  max-width: rem($layout-max-width-global);

  @media print {
    border-left: 0 !important;
    border-right: 0 !important;
    max-width: none !important;
  }

  @include min-width($layout-max-width-global) {
    border-left: 1px solid $border-gray;
    border-right: 1px solid $border-gray;
  }

  // Temporary fix for detail nodes without a left nav
  // (e.g. /tm/biography/christian-sabella)
  body:not(.node--type-landing-page) &:not(.has-sidenav) {
    @include min-width($layout-rightrail-bp) {
      border-left: 1px solid $border-gray;
      border-right: 1px solid $border-gray;
      max-width: rem(1016px);

      @media print {
        max-width: none;
      }
    }
  }

  // Pages that use the banner and are full width
  body &:not(.has-sidenav):has(.page-layout-type--layout-heading-banner__full-width)  {
    @include min-width($layout-rightrail-bp) {
      border-left: 1px solid $border-gray;
      border-right: 1px solid $border-gray;
      max-width: rem($layout-max-width-global);
    }
  }

  .page-layout-type--layout-heading-banner__full-width {
    max-width: none;
  }

  // This is a direct child of <main>
  &__main {
    @include default-page-bottom;
    @include default-padding;
    margin-left: auto;
    margin-right: auto;
    max-width: rem($layout-max-width);

    @media print {
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: none !important;
    }

    // Temporary fix for detail nodes without a left nav
    // (e.g. /tm/biography/christian-sabella)
    .main-content:not(.has-sidenav) &.page-layout-type--layout-details {
      max-width: none;

      // Right rail overrides
      .node-details-layout__rightrail-region {
        @include min-width($layout-rightrail-bp) {
          margin-left: rem(55px);
        }
      }
    }

    // Note: The “page-layout-type” classes are added to the “.main-content__main”
    // wrapper in /templates/system/layout/page.html.twig
    &.page-layout-type {
      // The “simple” layout applies to basic page, comment form, data distribution, data viz,
      // enforcement release, event, hero (flexslider), image, individual defendant,
      // link, regulatory files, news, site alert, static file, video, and webcast
      &--layout-simple,
      &--layout-error-page {
        @include default-padding;

        .content-wrapper {
          @include default-page-top;

          @media print {
            padding-top: 20pt;
          }
        }
      }

      // The “details” layout applies to article, person
      &--layout-details {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        // Ensure content well is at least as tall as the left nav
        > .region:only-child {
          height: 100%;

          > .block-system-main-block {
            height: 100%;
          }
        }
      }

      // Landing pages with banner (also has “node--type-landing-page” class on body)
      // &--layout-heading-banner {}
    }

    // Define the default container query wrapper
    // Note: In multi-column layouts, each column is set
    //       as a container (see _layout-columns.scss)
    .content-wrapper {
      container-type: inline-size;
    }
  }

  // Don’t extend “l-fullwidth” beyond the global max-width in 1-col layout
  &:not(.has-sidenav) {
    #{$selector}__main {
      // Update the full-width styles to stop at the global max-width
      .l-fullwidth {
        @include min-width($layout-max-width-global) {
          left: 50%;
          margin-left: auto;
          margin-right: auto;
          right: auto;
          transform: translateX(-50%);
          width: rem($layout-max-width-global);
        }
      }
    }
  }

  // Left nav layout
  &.has-sidenav {
    @include layout-sidenav-wrap;

    #{$selector}__sidenav {
      @include layout-sidenav-aside;
    }

    #{$selector}__main {
      @include layout-sidenav-main;

      // Pages with right rail
      &.page-layout-type--layout-details {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
