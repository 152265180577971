//------------------------------------------------------------------------
// Icon and text component
//------------------------------------------------------------------------
.icon-text-list {
  // Note: “.icon-text” can be wrapped in a div so use * selector
  & > * {
    &:not(:last-child) {
      margin-bottom: rem(22px);
    }
  }
}
