//------------------------------------------------------------------------
// Details node custom styles
//------------------------------------------------------------------------
.node-details {
  $selector: &;

  &-layout {
    &.has-rightrail {
      @include layout-rightrail-wrap;
    }

    &__main-region {
      $field-gap_main-region: $field-default-vert-margin;
      // No right rail if only child
      &:only-child {
        @include default-padding;
        @include default-page-bottom;
        padding-top: rem(46px);// use padding so gray background isn’t visible
      }

      // Has right rail if not only child
      &:not(:only-child) {
        @include layout-rightrail-main;
      }

      // Default spacing between fields in the main section
      :where(&__content > .field:not(:last-child)) {
        margin-bottom: $field-gap_main-region;
      }

      // Person photo
      #{$selector}_image {
        // Hide photo in main content well in desktop layout
        @include min-width($layout-rightrail-bp) {
          display: none;
        }

        .field {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 40px;
        }
      }
    }

    &__rightrail-region {
      @include layout-rightrail-aside;

      .field__label {
        font-weight: bold;
      }

      // Person photo
      > #{$selector}_image {
        // Override default right rail padding
        padding: 0 !important;

        // Hide right rail photo in mobile layout
        @include max-width($layout-rightrail-bp - 1) {
          display: none;
        }
      }

      .rightrail-list {
        font-size: rem(16px);
        font-weight: 700;
      }
    }
  }
}
