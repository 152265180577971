//------------------------------------------------------------------------
// Publicly Available Data - Field Indicator
//
// When a user is entering data on a webform that would be publicly available, this is indicated to the user.
// There must have already been help text at the top of the page if you are going to add this to a webform.
// This is similar to a required field indicator
//
// The following markup can be added in a label field on a webform AFTER the label text.
// <span class="publicly-available"><span class="visually-hidden">Publicly Available Value</span></span>
//------------------------------------------------------------------------

.publicly-available {
  background-image: svg-url('public', (fill: $c-blue));
  background-position: 0px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  padding: 3px 9px 3px 11px;
  -webkit-print-color-adjust: exact;

  .usa-label & {
    padding: 0 9px;
    margin-left: 3px;
  }

  .usa-label.usa-input--required & {
    margin-right: -3px;
  }
}
