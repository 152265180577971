//------------------------------------------------------------------------
// File download button
// https://www.figma.com/file/sZ2xwStfidy1VqjNTHxKfx/SEC22-Design-System?node-id=7890-32930
//------------------------------------------------------------------------
.file-button {
  background-image: svg-url('download', (fill: #000));
  background-position: right 23px center;
  background-repeat: no-repeat;
  background-size: 14px auto;
  border-radius: 2px;
  color: $c-black;
  display: flex;
  max-width: rem(595px);
  min-height: rem(70px);
  overflow: hidden;
  text-decoration: none;
  transition: background-color 180ms ease;

  @media print {
    background-image: none;
    border: 1px solid currentColor;
    display: block;
    min-height: auto;
    padding: rem(14px);
    text-decoration: none !important;
  }

  &:visited  {
    color: $c-black;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba($button-blue-darker, 0.15);

    &:before {
      background-color: $button-blue-darker;
    }
  }

  // Page icon
  &:before {
    background-color: $button-blue-dark;
    background-image: svg-url('page', (fill: #fff));
    background-position: center;
    background-repeat: no-repeat;
    background-size: 23px auto;
    content: '';
    flex-shrink: 0;
    transition: background-color 180ms ease;
    width: rem(46px);

    @media print {
      display: none !important;
    }
  }

  // href text shown in print view
  &:after {
    // Override default link print styles
    @media print {
      display: block !important;
      content: attr(href) !important;
      margin-top: rem(4px);
      text-decoration: none !important;
    }
  }

  &__content {
    align-items: center;
    background-color: rgba($bg-blue, 0.15);
    display: flex;
    flex-grow: 1;
    padding: rem(14px 60px 14px 20px);

    @media print {
      display: inline-block;
      padding: 0;
    }
  }

  // Note: This wrapper is only needed to prevent the file name and metadata
  //       from becoming flex children, which would mess up the layout.
  &__text {}

  &__name {
    font-size: rem(18px);
    font-weight: bold;
    line-height: 1.2;

    &:not(:last-child) {
      margin-right: rem(4px);
    }
  }

  &__meta {
    font-size: rem(16px);
    font-weight: normal;
    white-space: nowrap;
  }
}
