//------------------------------------------------------------------------
// General Form Styles
//------------------------------------------------------------------------
.usa-form {
  max-width: none;

  &-group {
    @include usa.at-media("mobile-lg") {
      max-width: usa.units("tablet");
    }

    &.mutivalue-form-item {
      [role="dialog"] & {
        @include usa.at-media("mobile-lg") {
          max-width: none;
        }
      }
    }

    .fieldset__wrapper > *:first-child > & {
      margin-top: 0;
    }

    // allow form groups with class usa-prose to be wider
    &.usa-prose {
      max-width: initial;
    }
  }

  .usa-table {
    @include usa.at-media("mobile-lg") {
      max-width: usa.units("tablet");
    }

    [role="dialog"] & {
      @include usa.at-media("mobile-lg") {
        max-width: none;
      }
    }

    th {
      // Adjust asterisk position in <th> tags
      .form-required:after {
        margin-left: 0.25em;
      }
    }
  }

  &,
  &-group,
  .usa-table {
    // Adds asterisk to required fields
    // Note: Fields still need `aria-required="true"` in the markup
    // Note: Help text at the top of form the explains the "*"
    .form-required:after {
      color: $c-red;
      content: "*";
      display: inline-block;
      font-size: 1rem;
      font-weight: 900;
      // margin-left: 0.15em;
      margin-right: 0.15em;
    }

    // TODO: where should this be moved to?
    // Adds padding to the password suggestions
    .password-suggestions ul {
      padding-left: 1.2rem;
    }
  }
}

//------------------------------------------------------------------------
// Required
//------------------------------------------------------------------------
// required help text - when the entire text is red
.required-help {
  color: $c-red;
}

// required asterisk only - when only the asterisk is red
.required-asterisk {
  color: $c-red;
  font-size: 1rem;
  font-weight: 900;
}

// TODO: What is this for?
.ui-menu {
  .ui-state-focus,
  .ui-state-active {
    margin: 0;
  }
}

//------------------------------------------------------------------------
// Inline containers
// Example: date time field and form-item-scheduling-options-actions-bundle
//------------------------------------------------------------------------
.container-inline {
  div,
  label {
    display: inline-block;
  }

  .usa-form-group {
    margin-top: 0;
  }
}

//------------------------------------------------------------------------
// Read Only Styles that mimic disabled styles
//------------------------------------------------------------------------
.usa-input {
  &:read-only,
  &[readonly] {
    @include usa.u-disabled;
    @include usa.u-disabled-high-contrast-border;
    // Fix for Safari
    -webkit-text-fill-color: color("disabled-dark");
  }
}