//------------------------------------------------------------------------
// OASB Wizard Tools
//------------------------------------------------------------------------
:where(#decision-tree) {
  $bp-end-scale: 768px;
  $bp-callout-end-scale: 1024px;
  $img_path: '/themes/custom/uswds_sec/dist/img/dt_images/';

  .sr-only {
    @include visually-hidden;
  }

  .progress-container {
    background-color: $button-gray-light;
    margin: 1rem 0;

    .progress-bar {
      background-color: $button-blue-dark;
      color: #fff;
      min-width: 50px;
      padding: 2px 6px;
      text-align: right;
    }
  }

  .all-choices {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: .5rem;
    grid-template-columns: 1fr;
    padding: 1.5rem 0 0.6rem;

    @include min-width($bp-end-scale) {
      grid-gap: .75rem;
      grid-template-columns: repeat(3, max(160px));
    }
  }

  .dt-img-btn {
    align-items: center;
    border: 1px solid $border-gray-light;
    color: var(--card-text-color, $c-blue);
    display: flex;
    flex: 1;
    gap: 1rem;
    justify-content: center;
    padding: .7rem;
    transition: background-color 180ms ease, color 180ms ease;

    @include min-width($bp-end-scale) {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      padding: 1.5rem;
    }

    &:hover,
    &:focus,
    &.selected {
      background-color: rgba($bg-blue, 0.10);
      border-color: $link-color-hover;
      text-decoration: none;
    }

    .img {
      align-self: center;

      @include min-width($bp-end-scale) {
        font-size: rem(20px);
      }

      > img {
        height: auto;
        width: 40px;

        @include min-width($bp-end-scale) {
          width: 80px;
        }
      }
    }

    .text {
      font-size: 1.1rem;
      font-weight: 600;
      margin-top: 0.3rem;
      text-align: left;
      text-decoration: none;

      @include max-width(400px) {
        max-width: 130px;
      }

      @include min-width($bp-end-scale) {
        font-size: 1.1rem;
        text-align: center;
      }
    }
  }

  .utility-buttons {
    display: flex;
    gap: 20px;
    margin-top: 0.7rem;
  }

  .callout {
    float: none;
    margin-top: 1.4rem;
    max-width: auto;
    padding: 1rem;
    text-align: center;

    @include min-width($bp-callout-end-scale) {
      float: right;
      max-width: 25%;
    }

    h3 {
      background-image: url($img_path + 'icon-q2-did_you_know_lightbulb.png');
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 21px;
      display: flex;
      font-size: 1rem;
      justify-content: center;
      margin-bottom: 0.4rem;
      margin: 0 auto;
      max-width: 141px;
      padding: 2px 0 0 16px;
      text-align: left;
    }

    &-text {
      font-size: 0.8rem;
      line-height: 1.5;

      &-source {
        color: $c-gray-light;
        font-size: 0.7rem;
        font-style: italic;
        line-height: 0.9rem;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
    }
  }

  .question {
    margin-bottom: 0.5rem;
  }

  // Exclusive for question How much money do you plan to raise?
  [data-all-choices-id="6"] {
    grid-template-columns: 1fr;

    @include min-width($bp-end-scale) {
      grid-template-columns: 300px;
      justify-content: space-around;
    }
  }

  .other-results-row,
  .relevant-results-row {
    display: grid;
    grid-gap: .75rem;
    grid-template-columns: 1fr;
    margin-top: 0.5rem;
    margin: 1.5rem 0;
    text-align: center;

    @include min-width($bp-end-scale) {
      grid-template-columns: 1fr 1fr 1fr;

      .dt-img-btn {
        min-height: 12.5rem;
      }
    }
  }
}
