//------------------------------------------------------------------------
// View: Regulatory Releases - Rulemaking Activity
//------------------------------------------------------------------------

.view-id-regulatory_releases {
  $subtext--color: $c-gray;

  // Exposed Filter Tweaks
  @include min-width($layout-max-width-medium) {
    .form-item-search {
      max-width: 15rem;
    }
  }

  // Rule Making Column
  .view-field-custom--rulemaking {
    .division {
      font-style: italic;
      font-size: 0.9em;
      color: $subtext--color;
    }
  }

  // Status Column
  .view-field-custom--status {
    min-width: 400px;
    padding: 0.25rem 0.25rem;
  }

  .more-link {
    text-align: right;
    margin-top: 5px;
    display: block;
  }
}
