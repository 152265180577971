//------------------------------------------------------------------------
// Media Types using the Title Only View Mode
//------------------------------------------------------------------------
// Media static files title only
.media--type-static-file.media--view-mode-title-only {
  // External link text is wrapped in a <div>, breaking the external icon
  // styles, so we’re applying “display: contents;” to fix it.
  :where(.file_embed_link > .field--name-field-display-title) {
    display: contents;
  }
}
