//------------------------------------------------------------------------
// Button group overrides
// https://designsystem.digital.gov/components/button-group/
//------------------------------------------------------------------------
.usa-button-group {
  align-items: stretch;

  > * {
    max-width: 100%;// Stacks the buttons and sets them to full width when screen resolution in below 480px.

    @include usa.at-media("mobile-lg") {
      max-width: calc(50% - rem(30px));
    }

    &:not(.usa-button) .usa-button {
      height: 100%;
    }
  }

  .usa-button {
    display: flex;
    align-items: center;
    word-break: break-word;

    &::after {
      flex-shrink: 0;
    }
  }

  // Don’t apply to “segmented” groups with no space between buttons
  // Overriding USWDS - using gap instead of margin.
  &:where(:not(.usa-button-group--segmented)) {
    gap: #{rem(30px)};
    margin-left: 0;
    margin-right: 0;
  }
}
