//------------------------------------------------------------------------
// View: SRO Rules
//------------------------------------------------------------------------

.view-sro-rules {
  // Exposed Form Tweeks
  @include min-width($layout-max-width-medium) {
    .views-exposed-form {
      .form-item-release-number {
        max-width: 14rem;
      }
    }
  }
}
