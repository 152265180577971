// Vocabulary Term: File Number
// AKA Administrative Proceeding File No.
// AKA AP Cases Details pages
//
// This page displays two views that are combined via JS in the
// /assets/js/modules/enforcementIndexTableCombineAndSort.js
// --------------------------------------------------

.rulemaking-table-nodes {
  // --------------------------------------------------
  // Style
  // --------------------------------------------------

  .views-field-field-release-number-1 {
    white-space: nowrap;
    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }


  // --------------------------------------------------
  // Functionality
  // If there is JS, Hide areas of the page so no flashing of data appears during processing
  // If there is no js, then show all data to the use in two tables not sorted or combined in any way  // --------------------------------------------------
  // Hide tables on the page until they have been processed by js
  .usa-table {
    display: none;
    @media (scripting: none) {
      display: block;
    }
  }

  // Show tables if they are contained in the process div
  .processed-table .usa-table {
    display: block;
  }

  // Hide footer, then display it using js after table is processed
  & > .view-footer,
  & > .view-content {
    display: none;
    @media (scripting: none) {
      display: block;
    }
  }

  // if there is no js, then hide
  .documents-title, .processed-table {
    @media (scripting: none) {
      display: none;
    }
  }
}
