//------------------------------------------------------------------------
// Style guide template styles (/sec-styleguide)
//
// Note: We can’t add these to /sec_styleguide/assets/css/styles.css
//       since it’s not setup to compile Sass.
//------------------------------------------------------------------------
.path-sec-styleguide {
  .block-system-main-block {
    // Note: Only apply prose styles to direct children to avoid conflicts
    //       with components since they’re all siblings in the style guide.
    @include sec-prose($use-child-selector: true);

    & > {
      // Headings
      h2 {
        @include h2($include-margin: true);
      }

      h3 {
        @include h3($include-margin: true);
      }

      h4 {
        @include h4($include-margin: true);
      }

      h5 {
        @include h5($include-margin: true);
      }

      h6 {
        @include h6($include-margin: true);
      }
    }
  }
}
