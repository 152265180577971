//------------------------------------------------------------------------
// Override legacy data table module styles from “sec_view_filter_plugin”
//
// Original CSS: /sec_view_filter_plugin/css/sec_datatable.css
//------------------------------------------------------------------------

// Show table content when JS is disabled
.no-js .en-datatable {
  display: block;
}

// Note: Duplicate the top-level selector to increase specificity so we
//       can override the original CSS without needing !important.
.dataTable.dataTable {
  $icon-size: 24px;

  // Sortable column headings
  // ------------------------
  // Note: <th> elements don’t share a common class name so we have to do this
  // 1. The original styles use !important here so we have to use it too
  th[class*="sorting"] {
    background-size: $icon-size;
    background-position: right center !important;// [1]
    padding-right: $icon-size;
  }

  // Up and down arrow
  .sorting {
    background-image: svg-url('arrowUpDown', (fill: $c-black));
  }

  // Down arrow
  .sorting_desc {
    background-image: svg-url('arrowDown', (fill: $c-black));
    @media print {
      background: none;
    }
  }

  // Up arrow
  .sorting_asc {
    background-image: svg-url('arrowUp', (fill: $c-black));
    @media print {
      background: none;
    }
  }

  // Group headings
  // --------------
  tr.group > td {
    @include font-smoothing;
    background-color: $button-blue-dark;
    color: #fff;
    font-family: $body-copy-font;
    font-weight: bold;
    padding: rem(10px);
    text-align: left;
  }

}
