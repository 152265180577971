//------------------------------------------------------------------------
// Help Text
//------------------------------------------------------------------------
.usa-hint,
.text-count-wrapper {
  --usa-hint-fs: .8rem;
  margin-top: 0.2rem;
  font-size: var(--usa-hint-fs);
  color: #71767a;

  .form-radios + &,
  .form-checkboxes + &,
  .usa-radios &,
  .usa-checkbox & {
    margin-top: usa.units(1);
  }

  code, kbd, samp, pre, var {
    font-size: var(--usa-hint-fs);
  }
}
