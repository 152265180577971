//------------------------------------------------------------------------
// Form accordion (aka details)
//------------------------------------------------------------------------
.usa-accordion {
  .usa-accordion {
    list-style: none;
  }

  &__content {
    > div,
    > fieldset {
      &:first-of-type {
        margin-top: 0;

        > .usa-form-group:first-child,
        > .usa-checkbox:first-of-type .usa-label,
        > .usa-radio:first-of-type .usa-label {
          margin-top: 0;
        }
      }
    }

    .usa-accordion--bordered & {
      max-width: 100%;
    }
  }

  .usa-form & {
    margin-top: usa.units(3);

    & + & {
      margin-top: 0;
    }
  }
}
