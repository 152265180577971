//------------------------------------------------------------------------
// News node custom styles
//------------------------------------------------------------------------
.node--type-news {

  // Add spacing to fields in main section
  .bar-separated {
    margin-bottom: $field-default-vert-margin;
  }

  //------------------------------------------------------------------------
  // Press Release
  //------------------------------------------------------------------------

  .field--name-field-sub-title {
    font-size: rem(26px);
  }
  // @include visually-hidden;
  @include separated-list('.field--name-field-release-number','.field__item', ',\00a0');

  .news__press_release__end {
    text-align: center;
    margin-bottom: $field-default-vert-margin;
  }

  .news__press_release__immediate_release {
    text-transform: uppercase;
    font-weight: 600;
  }

  //------------------------------------------------------------------------
  // Speeches Statements, and Testimonies
  //------------------------------------------------------------------------
  .field--name-dynamic-twig-fieldnode-news-speaker {

    .field_person_wrapper{
      font-size: rem(22px);
      font-weight: 600;
      line-height: $body-copy-line-height;
    }

    .field--name-field-speaker-name-and-title{
      font-size: rem(22px);
      font-weight: 600;
      p {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .person__current_position {
    font-style: italic;
  }

  .field--name-dynamic-twig-fieldnode-press-release-lead-in {
    display: inline;
    float: left;
    line-height: 1.5;
    margin-bottom: 0;
  }

  .node-details-layout--news--statement {
    .field--name-field-location-news {
      display: none;
    }
  }
}
