//------------------------------------------------------------------------
// Tag List
//------------------------------------------------------------------------
// TODO: Is this still needed now that we have the “tag_group” pattern?
.tag-list {
  $col-gap: rem(8px);
  $row-gap: rem(8px);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-$col-gap);
  margin-top: rem(-$row-gap);

  > .usa-tag {
    margin-left: rem($col-gap);
    margin-top: rem($row-gap);
  }
}
