//------------------------------------------------------------------------
// Drupal off-canvas overrides
//
// This includes overrides to the layout builder sidebar flyout
//------------------------------------------------------------------------
#drupal-off-canvas {
  $bg-gray: #474747;
  $bg-gray-dark: #333;
  $font-color: #eee;

  > div a {
    color: $font-color;
    margin-left: 0.3rem;
  }

  .form-type-search {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  

  @at-root .user-logged-in .ui-dialog-off-canvas {
    z-index: $z-admin--off-canvas;
  }

  // CSS for right rail when adding a block
  .inline-block-list {
    li {
      margin-top: -3px;
    }
  }

  .block-categories {
    [hidden] {
      display: none;
    }
    .usa-accordion {
      background: $bg-gray;
      padding: 0;
      border: none;
      margin: 0;

      .links li {
        a {
          color : $font-color;
        }&:hover {
          text-decoration: underline;
        }

        &:last-child a {
          padding-bottom: 0.4rem;
        }

      }
    }
    ul {
      list-style-type: none;

      li {
        font-size: 0.8em;
      }
    }

    button,
    .button {
      color: $font-color;
      min-width: 298px;
      margin-left: -20px;
      padding: 24px 0;
      text-align: left;
      padding: 15px 25px;
      font-size: rem(16px);
      cursor: pointer;
      background: $bg-gray;

      &[aria-expanded="true"] {
        background: $bg-gray;
      }

      &:hover,
      &:focus {
        background: $bg-gray-dark;
        text-decoration: none;
        transition: all 0.5s ease;
      }
    }

    
  }

  // CSS for right rail when adding a section
  .item-list {
    margin-left: rem(-20px);
    margin-right: rem(-20px);
    ul {
      li {
        padding-bottom: 0rem !important;
        a {
          padding-bottom: 1rem;
          margin-left: 0.3rem;
          margin-right: 0.4rem;
          color: $font-color;
        }
  
        &:hover {
          transition: all 0.5s ease;
          background: $bg-gray-dark;
        }
  
        &:last-child {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}
