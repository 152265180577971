//------------------------------------------------------------------------
// Styles for Release Topper Block
//------------------------------------------------------------------------

.block--type-release-topper {
  margin-bottom: 2rem;

  // Quick links area layout and styles
  @include layout-release-quicklinks('.release-group', '.field--name-field-introduction', '.field--name-field-customized-quick-links');
}
