//------------------------------------------------------------------------
// Button overrides and custom variations
// https://designsystem.digital.gov/components/button/
//------------------------------------------------------------------------
// Note: We’re reapplying the background and text colors using CSS vars
//       so we can easily change them on dark backgrounds.
.usa-button {
  @include usa-button;

  &--big {
    font-size: rem(16px);
    text-transform: none;
  }

  // The small button is only intended for use in the Drupal admin
  // Note: The button uses input[type="submit"] in the admin
  // &.button--small, &.button--extrasmall were added to use drupal classes that were already in place
  &--small,
  &.button--small,
  &.button--extrasmall {
    &,
    // Add “ui-widget” class to override jQuery UI input styles
    .ui-widget & {
      @include usa-button--small;
    }
  }

  &.lang-select{
    text-transform: capitalize !important;
  }

  // SEC secondary button uses different colors than USWDS default
  &--secondary {
    background-color: var(--theme-button-bg-color, $button-teal-dark);

    &:hover,
    &:focus,
    &:active,
    &.usa-button--hover,
    &.usa-button--focus,
    &.usa-button--active {
      background-color: var(--theme-button-hover-bg-color, $button-teal-darker);
    }
  }

  // SEC base button uses a different colors than USWDS default
  &--base {
    --theme-button-bg-color: #{$button-gray-lighter};
    --theme-button-color: #{$c-black};
    --theme-button-hover-bg-color: #{$button-gray-med-light};
    --theme-button-icon-color: #{$c-blue};
  }

  // Use CSS vars for outline button colors to auto-adjust on dark backgrounds
  // Note: USWDS uses inset box-shadow for the outline
  &--outline {
    --theme-button-icon-color: currentColor;
    @include undo-font-smoothing;
    background-color: transparent !important;// override usa-button--hover/focus/active classes
    box-shadow: inset 0 0 0 2px currentColor !important;// override default USWDS style

    @media print {
      --theme-button-icon-color: #000;
      --theme-outline-button-color: #000;
    }

    &,
    &:visited,
    .usa-form &:visited {
      color: var(--theme-outline-button-color, $button-blue-dark);
    }

    &:hover,
    &:focus,
    &:active,
    &.usa-button--hover,
    &.usa-button--focus,
    &.usa-button--active {
      color: var(--theme-outline-button-color, $button-blue-darker) !important;
    }

    &.usa-button--disabled {
      background-color: $button-gray !important ;
      box-shadow: none !important;
      color: #fff !important;
    }
  }

  // Same as USWDS default
  // &--big {}

  &--unstyled {
    background-color: transparent !important;
    color: var(--theme-text-color, $c-blue) !important;
    text-transform: initial;

    &.usa-button--disabled {
      --theme-text-color: #{$button-gray};
    }

    @media print {
      box-shadow: none !important;
    }
  }
}
