// -------------------------------------------------------------------------
// Use Sass vars to store z-index of certain global components
// -------------------------------------------------------------------------
// Drupal admin elements
$z-admin--off-canvas: 1030;
$z-admin-toolbar: 1020;
$z-admin-environmental: 1010;

$z-skip-links: 999;
$z-header: 800;
$z-nav: 700;
$z-main: 100;
