//------------------------------------------------------------------------
// Gallery
//------------------------------------------------------------------------

.media-gallery{
  $gallery-gap: 16px;
  display: grid;
  gap: $gallery-gap;

  // if auto is added as an option, then add this code in
  // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  &[data-cols="2"] {  grid-template-columns: repeat(2, 1fr);}
  &[data-cols="3"] {  grid-template-columns: repeat(3, 1fr);}
  &[data-cols="4"] {  grid-template-columns: repeat(4, 1fr);}

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr !important;
  }

  &__item {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    align-items: start;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    aspect-ratio: 3 / 2;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ededed;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      background-color: #fff;
    }
  }

  &__caption {
    padding: 10px 10px 0 10px;
    text-align: center;
    font-size: 14px;
      color: #5e5e5e;
      font-style: italic;
  }

// If gallery paragraphs are next to each other in the same component, reduce spacing between
  .field__item:has(>&)+.field__item:has(>&) {
    margin-top: $gallery-gap;
  }
}
