// Layout mixins
@use "../vars/color-vars" as *;
@use "../vars/layout-vars" as *;
@use "../functions/rem" as *;
@use "../mixins/link-mixins" as *;
@use "../mixins/typography" as *;
@use "media-queries" as *;
@use "scale" as *;
@use "uswds-settings" as usa;

// Default horizontal padding to prevent content from touching the viewport edges
@mixin default-padding() {
  @include scale(padding-left padding-right, (
    $layout-padding-min-bp: $layout-padding-min,
    $layout-padding-max-bp: $layout-padding-max
  ));
}

// Default vertical margin above and below the main content well
@mixin default-page-top($prop: padding-top) {
  @include scale($prop, (320px: 40px, $layout-max-width-bp: 60px));
}

@mixin default-page-bottom($prop: padding-bottom) {
  @include scale($prop, (320px: 60px, $layout-max-width-bp: 100px));
}

@mixin section-margin-top() {
  @include scale(margin-top, (375px: 40px, $layout-max-width-bp: 60px));
}

// Card left/right padding
// Note: Also used to inset “.usa-prose” content so its left aligned with card text
@mixin card-horz-padding() {
  @include scale(padding-left padding-right, (
    375px: $layout-card-horz-padding-min,
    460px: $layout-card-horz-padding-max
  ));
}

// Make element 100vw width regardless of parent width
// NOTE: These styles only work when the parent wrapper is centered.
// https://css-tricks.com/full-width-containers-limited-width-parents/#aa-no-calc-needed
@mixin layout-fullwidth($important: false, $position: relative) {
  left: 50% #{if($important, ' !important', '')};
  margin-left: -50vw #{if($important, ' !important', '')};
  margin-right: -50vw #{if($important, ' !important', '')};// for RTL languages
  position: $position #{if($important, ' !important', '')};
  right: 50% #{if($important, ' !important', '')};// for RTL languages
  width: 100vw #{if($important, ' !important', '')};
}

@mixin layout-fullwidth-sidebar() {
  @include layout-fullwidth;

  @include min-width($layout-rightrail-bp) {
    left: calc(50% + #{rem($layout-rightrail-width * 0.5)});
    right: calc(50% + #{rem($layout-rightrail-width * 0.5)});
    width: calc(100vw - #{rem($layout-rightrail-width)});
  }
}

// Left sidenav layout (used on landing pages)
@mixin layout-sidenav-wrap() {
  @include min-width($layout-sidenav-bp) {
    display: flex;
  }
}

@mixin layout-sidenav-main() {
  @include default-padding;
  background-color: $bg-default;
  max-width: none;// override default layout styles

  @include min-width($layout-sidenav-bp) {
    flex-grow: 1;
    width: 100%;// IE11 fix
  }

  // Override “l-fullwidth” styles outside of main content (e.g. page header)
  .l-fullwidth {
    // Custom styles for when left nav is present
    @include min-width($layout-sidenav-bp) {
      margin-left: rem(-$layout-padding-max);
      margin-right: rem(-$layout-padding-max);
      left: auto;
      right: auto;
      width: calc(100% + #{$layout-padding-max * 2});

      @media print {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }

    // Use default fullwidth styles on homepage
    // 1. Cancel out custom “l-fullwidth” styles when there’s no sidenav or right rail
    .path-frontpage-plus & {
      @include layout-fullwidth($important: true);
      transform: none !important;// [1]
    }
  }

  // Update “l-fullwidth” when inside of main content well
  .region-content .l-fullwidth {
    $bp-max-width: $layout-sidenav-width-wide + $layout-max-width + ($layout-padding-max * 2);
    $horz-offset: ($layout-sidenav-width-wide + $layout-max-width) * 0.5;

    @include min-width($bp-max-width) {
      margin-left: calc(-50vw + #{rem($horz-offset)});
      margin-right: calc(-50vw + #{rem($horz-offset)});
      width: calc(100vw - #{$layout-sidenav-width-wide});
    }

    @include min-width($layout-max-width-global) {
      $main-width: $layout-max-width-global - $layout-sidenav-width-wide;
      $horz-offset: ($main-width - $layout-max-width) * 0.5;
      margin-left: rem(-$horz-offset + 1px);
      margin-right: rem(-$horz-offset + 1px);
      width: rem($main-width - 2px);
    }
  }

  // Reset fullwidth styles when there’s no left nav
  &:only-child {
    .region-content .l-fullwidth {
      @include layout-fullwidth;

      // Stay within main content well max-width
      @include min-width($layout-max-width-global) {
        left: 50%;
        margin-left: 0;
        margin-right: 0;
        right: auto;
        transform: translateX(-50%);
        width: rem($layout-max-width-global - 2px);
      }
    }
  }
}

@mixin layout-sidenav-aside() {
  background-color: $bg-gray-med-light;
  border-right: 1px solid $border-gray;

  @media print {
    display: none;
  }

  @include max-width($layout-sidenav-bp - 1px) {
    .l-sidenav-aside {
      display: none;
    }
  }

  @include min-width($layout-sidenav-bp) {
    flex-shrink: 0;
    order: -1;// always on the left
    width: rem($layout-sidenav-width);
  }

  @include min-width($layout-sidenav-bp-wide) {
    width: rem($layout-sidenav-width-wide);
  }
}

// Right rail layout (used on default template)
@mixin layout-rightrail-wrap() {
  background-color: $bg-default;
  height: 100%;// ensure content well is at least as tall as left nav

  @include min-width($layout-rightrail-bp) {
    display: flex;
    padding-left: 0;// override default layout styles
    padding-right: 0;// override default layout styles
  }
}

@mixin layout-rightrail-main() {
  @include default-padding;
  container-type: inline-size;// define as container for container queries
  padding-top: rem(46px);// use padding so gray bg color isn’t visible

  @include max-width($layout-rightrail-bp - 1px) {
    @media print {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include min-width($layout-rightrail-bp) {
    @include default-page-bottom;
    box-sizing: content-box;// don’t include padding
    display: flex;// for “last updated” text positioning
    flex-direction: column;
    flex-grow: 1;
    max-width: rem($layout-max-width-narrow);
    order: -1;// always on left
    padding-left: rem($layout-rightrail-main-horz-padding);
    padding-right: 0;

    @media print {
      max-width: none;
    }

    &:only-child {
      max-width: rem($layout-max-width-medium);
      padding-right: rem($layout-rightrail-main-horz-padding);

      @media print {
        max-width: none;
      }
    }

    // Reset box-sizing on children, otherwise they will inherit
    * {
      box-sizing: border-box;
    }
  }

  @include min-width($layout-max-width-global) {
    padding-left: rem($layout-rightrail-main-horz-padding-wide);

    &:only-child {
      padding-right: rem($layout-rightrail-main-horz-padding-wide);
    }
  }

  > .content-wrapper {
    @include max-width($layout-rightrail-bp - 1px) {
      margin-left: auto;
      margin-right: auto;
      max-width: rem($layout-max-width-narrow);

      @media print {
        max-width: none;
      }
    }
  }

  // Override “l-fullwidth” styles
  .has-sidenav & {
    .l-fullwidth {
      @include min-width($layout-sidenav-bp) {
        margin-left: rem(-$layout-rightrail-main-horz-padding);
        margin-right: rem(-$layout-rightrail-main-horz-padding);
        width: calc(100vw - #{$layout-sidenav-width + $layout-rightrail-width});
      }

      @include min-width($layout-sidenav-bp-wide) {
        width: calc(100vw - #{$layout-sidenav-width-wide + $layout-rightrail-width});
      }

      @include min-width($layout-max-width-global) {
        margin-left: rem(-$layout-rightrail-main-horz-padding-wide);
        margin-right: 0;
        width: rem($layout-max-width-global - 2px - $layout-sidenav-width-wide - $layout-rightrail-width);
      }
    }

    // Update “l-fullwidth” when there’s no right rail
    &:only-child {
      .l-fullwidth {
        @include min-width($layout-sidenav-bp) {
          width: calc(100vw - #{$layout-sidenav-width});
        }

        @include min-width($layout-sidenav-bp-wide) {
          width: calc(100vw - #{$layout-sidenav-width-wide});
        }

        @include min-width($layout-max-width-global) {
          width: rem($layout-max-width-global - 2px - $layout-sidenav-width-wide);
        }
      }
    }
  }
}

@mixin layout-rightrail-aside() {
  $bp-main-max-width: $layout-sidenav-width-wide + $layout-max-width-narrow + $layout-rightrail-width + ($layout-rightrail-main-horz-padding * 2);
  $border-color: $border-gray;
  background-color: $bg-gray-lighter;

  @include max-width($layout-rightrail-bp - 1px) {
    margin-top: rem(40px);
  }

  @include min-width($layout-rightrail-bp) {
    @include default-page-bottom;
    border-left: 1px solid $border-color;
    flex: 0 0 32%;
    margin-left: rem($layout-rightrail-main-horz-padding);
    max-width: rem($layout-rightrail-width);
  }

  @include min-width($bp-main-max-width) {
    margin-left: auto;
  }

  // Sections
  // TODO: Confirm markup with BE devs
  > div:not(:has(.details-section)), .details-section > div {
    font-size: rem(14px);
    line-height: 1.5;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    @include max-width($layout-rightrail-bp - 1px) {
      @include default-padding;
      padding-bottom: rem(50px);
      padding-top: rem(40px);

      @media print {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include min-width($layout-rightrail-bp) {
      padding-left: rem(30px);
      padding-right: rem(30px);
      padding-top: rem(40px);

      &:not(:last-child) {
        padding-bottom: rem(30px);
      }
    }
  }

  // Sidebar headings
  // all h2s in the sidebar should be section labels
  h2 {
    @include upper-heading;
    border-bottom: 1px solid $border-color;
    margin-bottom: rem(26px);
    padding-bottom: rem(26px);
  }

  // Reduce font-size (OSSS-23369)
  .usa-prose {
    font-size: rem(14px);
  }

  ul,
  ol,
  p {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  ul {
    margin-left: rem(14px);
  }

  .usa-collection {
    margin-left: 0;
  }

  ol {
    margin-left: rem(20px);
  }

  // a {
  //   @include usa.typeset-link;
  //   @include link-add-icons;
  // }

}
