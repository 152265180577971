.date-modified {
  $bp-right-align: 420px;

  margin-top: $field-default-vert-margin;

  // Desktop
  @include min-width($bp-right-align) {
    text-align: right;
  }

}