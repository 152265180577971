//------------------------------------------------------------------------
// Person node custom styles
//------------------------------------------------------------------------
.node--type-secperson {
  .node-details-layout__main-region {
    // add space between fields that use components
    .field--type-entity-reference-revisions {
      > .field__item + .field__item {
        margin-top: $field-component-vert-margin;
      }
    }
  }

  // remove field spacing from image
  .media--view-mode-person-sidebar .field--name-field-media-image {
    margin-bottom: 0;
  }
}
