//------------------------------------------------------------------------
// Custom web fonts not included with USWDS
//
// Note: Only woff and woff2 are required for modern browsers
// https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
//
// We’re using “font-display: fallback” as a compromise between
// performance and minimizing the chances of a FOIT or FOUC.
//
//   “The browser will hide the text for about 100ms and, if the font
//   has not yet been downloaded, will use the fallback text. It will
//   swap to the new font after it is downloaded, but only during a
//   short swap period (probably 3 seconds).”
//   https://css-tricks.com/almanac/properties/f/font-display/
//
//------------------------------------------------------------------------
// Custom emoji font to standardize appearance across platforms
// https://www.client9.com/css-color-emoji-stack/
// https://nolanlawson.com/2022/04/08/the-struggle-of-using-native-emoji-on-the-web/
/* stylelint-disable */
@font-face {
  font-family: 'color-emoji';
  src: local('Apple Color Emoji'),
       local('Twemoji Mozilla'),
       local('Segoe UI Emoji'),
       local('Segoe UI Symbol'),
       local('Noto Color Emoji'),
       local('EmojiOne Color'),
       local('Android Emoji');
}

// Gotham
// FIXME: We likely need to include some tracking code from typography.com
// https://www.typography.com/fonts/gotham/styles
/*
	Copyright (C) Hoefler & Co.
	This software is the property of Hoefler & Co. (H&Co).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&Co. If no such agreement exists, you may not
	access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&Co.
	For more information, please visit us at http://typography.com.
*/
// Book
@font-face {
  font-display: fallback;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('#{usa.$theme-font-path}/gotham/GothamSSm-Book_Web.woff2') format('woff2'),
       url('#{usa.$theme-font-path}/gotham/GothamSSm-Book_Web.woff') format('woff');
}

// Medium
@font-face {
  font-display: fallback;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('#{usa.$theme-font-path}/gotham/GothamSSm-Medium_Web.woff2') format('woff2'),
       url('#{usa.$theme-font-path}/gotham/GothamSSm-Medium_Web.woff') format('woff');
}

// Bold
@font-face {
  font-display: fallback;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('#{usa.$theme-font-path}/gotham/GothamSSm-Bold_Web.woff2') format('woff2'),
       url('#{usa.$theme-font-path}/gotham/GothamSSm-Bold_Web.woff') format('woff');
}
/* stylelint-enable */
