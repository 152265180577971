//------------------------------------------------------------------------
// Link mixins
//------------------------------------------------------------------------
@use "uswds-settings" as usa;
@use "../vars/color-vars" as *;
@use "../vars/file-types" as *;
@use "../functions/rem" as *;

// Base styles are applied to all <a> tags in _base.scss
// Note: These CSS vars are used to theme text inside “bg-[color]” classes
@mixin link-base() {
  color: var(--theme-text-color, $link-color);
  transition: color 180ms ease;

  // Note: The focus outline comes from default USWDS styles
  &:hover,
  &:focus {
    color: var(--theme-link-hover-color, $link-color-hover);
  }

  &:active {
    color: var(--theme-link-active-color, $link-color-active);
  }

  &:visited {
    color: var(--theme-link-visited-color, $link-color-visited);
  }
}

@mixin link-underline() {
  text-decoration: underline;
}

// Note: USWDS doesn’t have a mixin for this so we’re overriding usa.external-link()
// Note: Use ems so icon scales with font-size
@mixin add-download-icon($icon-size: 1.04em) {
  --icon-size: #{$icon-size};
  @include usa.external-link();
  padding-right: 0;// Override USWDS default
  display: inline;// prevent links that wrap from always starting on a new line

  &:after {
    background-image: url('/themes/custom/uswds_sec/dist/img/usa-icons/file_download.svg');
    height: var(--icon-size);
    margin-left: 0;
    margin-top: 0.32em;// magic number, adjust if icon size changes
    width: var(--icon-size);

    // Since icons rely on a mask and background-color, Chrome/Edge users on Windows
    // need to enable background graphics in print settings. However, it’s unlikely
    // most users will do this and will instead see a black box instead of the icon.
    // Since we’re already showing the full URL in print view, we’re hiding the icon.
    @media print {
      display: none !important;
      // Using box-shadow instead of a background color worked on Macs but not Windows.
      // box-shadow: inset 0 0 0 2em #000 !important;// use spread value > icon size
    }

    @supports (mask: url("")) {
      background-image: none;
      mask-image: url('/themes/custom/uswds_sec/dist/img/usa-icons/file_download.svg');
      mask-size: 1.75ex 2em;// override height (see OSSS-30311)
    }
  }
}

// Automatically append external link and download icons
// Note: We’re using :where() to keep specificity low
@mixin link-add-icons {
  // Automatically append external link icon to all absolute links
  // (i.e. href starts with “http”) that don’t contain `lndo.site`,
  // `//localhost`, or `sec.gov`.
  &:where([href^="http"]:not([href*="lndo.site"], [href*="//localhost"], [href*="sec.gov"])) {
    @include usa.external-link();
    display: inline;// prevent links that wrap from always starting on a new line
    padding-right: 0;// Override USWDS default of 1.75ex
  }

  // Automatically append download icon to file links
  // Note: $file-download-selector is generated in /vars/_file-types.scss
  // Note: Use :where() to keep specificty low
  &:where(#{$file-download-selector}) {
    @include add-download-icon;
  }
}
