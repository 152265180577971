//------------------------------------------------------------------------
// Wizard card
//------------------------------------------------------------------------
.wizard-card {
  $bp-end-scale: 480px;
  @include scale(padding-bottom, (375px: 10px, $bp-end-scale: 32px));
  @include scale(padding-left padding-right, (375px: 15px, $bp-end-scale: $layout-card-horz-padding-max));
  @include scale(padding-top, (375px: 12px, $bp-end-scale: 35px));
  background-color: $bg-blue-lighter;
  border: 1px solid $border-gray-light;
  color: var(--card-text-color, $c-blue);
  display: flex;
  flex-direction: column;
  font-size: rem(16px);
  line-height: 1.25;
  position: relative;// for link overlay
  text-decoration: none;
  transition: background-color 180ms ease, color 180ms ease;

  @media print {
    break-inside: avoid;
  }

  .usa-card-group & {
    @include min-width(768px) {
      min-height: rem(180px);
    }
  }

  // Hide external and download icons from
  &:before,
  &:after {
    display: none !important;
  }

  &:hover,
  &:active,
  &:focus {
    --card-text-color: #fff;
    @include font-smoothing;
    background-color: $c-blue;

    .usa-icon {
      transform: translateX(4px);

      @include min-width($bp-end-scale) {
        transform: translateX(9px);
      }
    }
  }

  // Note: “.usa-icon” includes “height: 1em; width: 1em;” from USWDS
  // Note: Since we can’t get each individual icon’s viewBox dimensions
  //       in the template, the size of the SVG element won’t match the
  //       use element, so the styles may not match Figma _exactly_.
  .usa-icon {
    fill: currentColor;
    height: 100%;
    margin-left: auto;// right align
    margin-top: auto;// bottom align in card row layout
    max-height: rem(27px);
    max-width: rem(27px);
    transition: transform 300ms $easeOutCubic;
    width: 100%;
  }

  .wizard-card-text {
    ol, ul {
      margin-bottom: 1em;
      margin-top: 1em;
      padding-left: 3ch;
    }
  }
}
