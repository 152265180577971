//------------------------------------------------------------------------
// Linked image component (used on person profile pages)
//------------------------------------------------------------------------
.image-link {
  $img-max-width: 260px;
  display: flex;// shrink wrapper to fit image width

  &__link {
    position: relative;// for icon

    &:hover,
    &:focus,
    &:active {
      .icon {
        background-color: lighten($c-black, 40.5%);// #828282
      }
    }

    img {
      @include max-width($layout-rightrail-bp - 1px) {
        max-width: rem($img-max-width);
      }

      @media print {
        max-width: rem($img-max-width);
      }
    }

    // Download icon
    .icon {
      $circle-size: 33px;
      background-color: $c-black;
      border-radius: 50%;
      bottom: 7px;
      color: #fff;
      height: rem($circle-size);
      padding: rem(4px 3px 2px);// adjust icon size and positioning
      position: absolute;
      right: 7px;
      transition: background-color 180ms ease;
      width: rem($circle-size);

      @media print {
        display: none !important;
      }
    }
  }

  // Note: No styles are currently needed for the <img> tag since the CSS reset
  //       is already applying “display: block; height: auto; max-width: 100%”
}
