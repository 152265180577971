// Card box shadow
@use "../vars/color-vars" as *;
@use "../vars/layout-vars" as *;
@use "../mixins/media-queries" as *;

@mixin card-box-shadow() {
  box-shadow: $card-box-shadow-mobile;

  @include min-width($layout-card-bp) {
    box-shadow: $card-box-shadow-desktop;
  }

  @media print {
    border: 1px solid currentColor;
    box-shadow: none !important;

    // Hide border if card is in right rail
    .node-details-layout__rightrail-region & {
      border: 0;
    }
  }
}
