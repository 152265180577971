//------------------------------------------------------------------------
// Inline form
//------------------------------------------------------------------------
.form--inline {
  display: inline-flex;
  flex-wrap: wrap;
  gap: rem(15px) rem(10px);
  margin-top: usa.units(2);

  &:first-child {
    margin-top: 0;
  }

  .usa-form-group {
    margin-top: 0;
    max-width: 300px;
    width: auto;

    @include max-width($layout-max-width-medium - 1px) {
      // added some extra specificity for fields that have been overwritten
      .views-exposed-form &.form-item {
        max-width: none;
        width: 100%;
      }
    }
  }

  .usa-button {
    align-self: end;
    margin-top: 0;
  }

  .form-actions {
    align-self: end;
    display: flex;
    gap: rem(15px) rem(10px);

    .usa-button {
      margin-top: 0;
    }
  }

  // form fields that are forced onto a single line but stretch the fill width
  &__stretch {
    display: flex;
    width: 100%;
    & > .usa-form-group {
      max-width: none;
      flex-grow: 1;
      input {
        max-width: none;
      }
    }
    & + .description {
      margin-top: rem(10px);
    }
    .usa-label {
      font-weight: bold;
    }
  }
}
