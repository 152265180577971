//------------------------------------------------------------------------
// Language Switcher
//------------------------------------------------------------------------

#usa_language_switcher {
    margin-bottom: 40px;
    a.language-link {
        color: black;
        text-decoration: none;
        font-size: 1.2rem;
        text-decoration: underline;
        text-underline-position: under;
        &.is-active {
            color: black;
            text-decoration: none;
        }
    }
}