@use "../vars/color-vars" as *;
@use "../vars/layout-vars" as *;
@use "../mixins/media-queries" as *;

@mixin pager-buttons() {
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 1.13rem;
  border: none;
  color: $c-blue;

  @include max-width($layout-pagination-buttons-bp - 1px) {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
