//------------------------------------------------------------------------
// Email alerts sign-up promo
//------------------------------------------------------------------------
.email-alerts {
  $bp-end-scale: 460px;
  $bp-compact-row: 800px;
  @include scale(padding-bottom, (375px: 32px, $bp-end-scale: 44px));
  @include scale(padding-top, (375px: 23px, $bp-end-scale: 39px));
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2, h3, h4, h5, h6 {
    margin-top: unset;
  }

  &.is-compact {
    padding: rem(21px 16px 20px);

    @container (min-width: #{$bp-compact-row}) {
      justify-content: flex-start;
      padding: rem(28px 30px 40px);
    }

    // Fallback styles for browsers that don’t support container-queries
    // https://caniuse.com/css-container-queries
    @supports not (container-type: inline-size) {
      @include min-width($bp-compact-row) {
        justify-content: flex-start;
        padding: rem(28px 30px 40px);
      }
    }
  }

  &__top {
    align-items: center;
    display: flex;
    flex-direction: column;

    .is-compact & {
      flex-direction: row;

      @container (min-width: #{$bp-compact-row}) {
        margin-right: auto;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          margin-right: auto;
        }
      }
    }
  }

  &__icon {
    fill: $bg-yellow;
    margin-bottom: rem(16px);

    .is-compact & {
      margin-bottom: 0;
    }

    // Note: “.usa-icon” includes “height: 1em; width: 1em;” from USWDS
    // Note: Since we can’t get each individual icon’s viewBox dimensions
    //       in the template, the size of the SVG element won’t match the
    //       use element, so the styles may not match Figma _exactly_.
    &.usa-icon {
      aspect-ratio: 1;
      flex-shrink: 0;
      height: auto;
      width: rem(42px);

      .is-compact & {
        width: rem(30px);

        @container (max-width: #{$bp-compact-row - 1px}) {
          width: rem(24px);
        }

        @supports not (container-type: inline-size) {
          @include max-width($bp-compact-row - 1px) {
            width: rem(24px);
          }
        }
      }
    }
  }

  &__heading {
    @include scale(font-size, (375px: 22px, $bp-end-scale: 24px));
    @include scale(margin-bottom, (375px: 20px, $bp-end-scale: 34px));
    font-family: $gotham-font-family;
    font-weight: bold;
    line-height: 1;
    max-width: rem(542px);
    text-align: center;

    .is-compact & {
      font-size: rem(20px);
      margin-bottom: 0;
      margin-left: rem(12px);

      @container (min-width: #{$bp-compact-row}) {
        flex-shrink: 0;
        font-size: rem(16px);
        text-align: left;

        &:after {
          content: ":";
        }
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          flex-shrink: 0;
          font-size: rem(16px);
          text-align: left;

          &:after {
            content: ":";
          }
        }
      }
    }
  }

  &__description {
    margin-bottom: rem(6px);
    margin-top: rem(4px);

    &.is-mobile {
      text-align: center;
      text-wrap: balance;

      @container (min-width: #{$bp-compact-row}) {
        display: none;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          display: none;
        }
      }
    }

    &.is-desktop {
      margin-left: rem(4px);

      @container (max-width: #{$bp-compact-row - 1px}) {
        display: none;
      }

      @supports not (container-type: inline-size) {
        @include max-width($bp-compact-row - 1px) {
          display: none;
        }
      }
    }
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .is-compact & {
      @container (min-width: #{$bp-compact-row}) {
        flex-direction: row;
        justify-content: center;
        margin-top: rem(2px);
        padding-left: rem(55px);// magic number to align with heading text
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          flex-direction: row;
          justify-content: center;
          margin-top: rem(2px);
          padding-left: rem(55px);// magic number to align with heading text
        }
      }
    }
  }

  &__label {
    font-size: rem(16px);
    line-height: 1.2;
    margin-bottom: rem(4px);
    margin-top: 0;// override USWDS default
  }

  &__input {
    max-width: rem(350px) !important;// override USWDS default

    .is-compact & {
      @container (min-width: #{$bp-compact-row}) {
        flex-grow: 1;
        margin-top: 0 !important;// override USWDS default
        max-width: none !important;// override USWDS default
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          flex-grow: 1;
          margin-top: 0 !important;// override USWDS default
          max-width: none !important;// override USWDS default
        }
      }
    }
  }

  &__submit {
    margin-top: rem(20px) !important;// override USWDS default
    width: auto;

    @media (min-width: 640px) and (max-width: 686px) {
      padding: rem(12px) rem(11px);
    }

    .is-compact & {
      margin-top: rem(10px) !important;// override USWDS default

      @container (min-width: #{$bp-compact-row}) {
        margin-left: rem(16px);
        margin-top: 0 !important;// override USWDS default
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-compact-row) {
          margin-left: rem(16px);
          margin-top: 0 !important;// override USWDS default
        }
      }
    }
  }
}
