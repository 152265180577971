//------------------------------------------------------------------------
// Person card
// See /templates/patterns/card/_card.scss for default card styles
//------------------------------------------------------------------------
.card-person {
  $bp-end-scale: 460px;
  $right-rail-selector: '.node-details-layout__rightrail-region';
  @include card-box-shadow;
  @include card-horz-padding;
  background-color: $bg-default;
  padding-bottom: rem(26px);
  padding-top: rem(30px);

  // when the card is in the right rail, it should not appear as a card
  #{$right-rail-selector} & {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  &__image {
    // Optional link
    &__link {
      display: block;
      width: fit-content;// fit to image width
    }

    img {
      display: block;
      height: auto;
      max-width: rem(137px);
      width: 100%;

      @include min-width($bp-end-scale) {
        max-width: rem(147px);
      }
    }
  }

  // add space if the person has an image
  &__image + &__name {
    margin-top: rem(22px);
  }

  &__name {
    font-size: rem(15px);
    font-weight: bold;
    line-height: 1.2;

    // Optional link (currently using “.usa-link” class)
    // &__link {}
  }

  &__text {
    font-size: rem(15px);
    line-height: 1.5;
    margin-top: rem(6px);
    margin-bottom: 0 !important;

    & + & {
      margin-top: rem(3px);
    }
  }

  // Used for email and phone links
  // NOTE: We could refactor this to use the “icon_text_list” pattern instead
  &__link {
    align-items: center;
    display: flex;
    line-height: 1.1;
    margin-top: rem(12px);
    width: fit-content;// otherwise “flex” will cause it to be 100% wide

    // Note: “.usa-icon” includes “height: 1em; width: 1em;” from USWDS
    // Note: Since we can’t get each individual icon’s viewBox dimensions
    //       in the template, the size of the SVG element won’t match the
    //       use element, so the styles may not match Figma _exactly_.
    .usa-icon {
      fill: $c-blue;
      flex-shrink: 0;
      height: 100%;
      margin-right: rem(8px);
      max-height: rem(27px);
      max-width: rem(27px);
      width: 100%;
    }
  }
}
