//------------------------------------------------------------------------
// Fieldsets and other grouped fields
//------------------------------------------------------------------------
.usa-fieldset {
  $fieldset-border: 1px solid usa.color('base-lighter');
  $fieldset-padding: usa.units(2);;
  border: $fieldset-border;
  margin-top: usa.units(3);
  padding: $fieldset-padding;

  @at-root .usa-form-group {
    &.form-item-files-file,
    &.form-item-usa-managed-file,
    &.js-form-type-managed-file,
    &.form-type-webform-document-file {
      border: $fieldset-border;
      padding: $fieldset-padding;
    }
  }

  .messages--error {
    color: $c-red;
  }

  & > .fieldset__wrapper {
    // Remove spacing inside fieldset on top items
    & > .usa-form-group,
    & > .usa-fieldset {

      &:first-child {
        margin-top: 0;
      }
    }

  }

  // when a description is put into a prefix (i.e. Webform desc text on radio buttons wrapped in a fieldset)
  .fieldset__prefix .description {
    margin-bottom: 1.5rem;
  }
}


