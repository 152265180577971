//------------------------------------------------------------------------
// Workbench moderation entity moderation form
//
// Block appears on a node when a user can moderate it
//------------------------------------------------------------------------
.entity-moderation-form {
  margin-bottom: rem(20px);
  padding-bottom: rem(20px);

  .button[type="submit"] {
    margin-bottom: 0;
  }
}
