//------------------------------------------------------------------------
// Autocomplete Styles
//------------------------------------------------------------------------
// muti-value autocomplete
.field--widget-entity-reference-autocomplete {
  .usa-table .usa-form-group:only-child {
    margin: 0;

    .usa-label.visually-hidden + input {
      margin: 0;
    }
  }
}

// dropdown
.ui-autocomplete {
  .ui-menu-item {
    display: block;
    width: 100%;

    .ui-menu-item-wrapper {
      display: block;
      padding: 0 0.4rem;
      width: 100%;
    }

    .ui-state-active {
      border: none;
      width: 100%;
    }
  }
}

// Loading Icon
.js input.form-autocomplete {
  background-position: right 7px center;
}
