//------------------------------------------------------------------------
// Override Table sorter Library tables with USWDS Styles
//
//------------------------------------------------------------------------

// Note: Duplicate the top-level selector to increase specificity so we
//       can override the original CSS without needing !important.
.tablesorter.tablesorter {
  $icon-size: 24px;

  // Sortable column headings
  // ------------------------
  [aria-sort] {
    background-size: $icon-size;
    background-position: right center !important;// [1]
    padding-right: $icon-size;
    background-color: #97d4ea;
    color: #1b1b1b;
    background-repeat: no-repeat;
  }

  [aria-sort="none"]{
    background-color: #dfe1e2;
    color: #1b1b1b;
  }

  // Up and down arrow
  .tablesorter-headerUnSorted {
    background-image: svg-url('arrowUpDown', (fill: $c-black));
  }

  // Down arrow
  .tablesorter-headerDesc {
    background-image: svg-url('arrowDown', (fill: $c-black));
    @media print {
      background: none;
    }
  }

  // Up arrow
  .tablesorter-headerAsc  {
    background-image: svg-url('arrowUp', (fill: $c-black));
    @media print {
      background: none;
    }
  }

}
