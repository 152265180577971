//------------------------------------------------------------------------
// View: Investment Company Act Notices and Orders
//------------------------------------------------------------------------
.view-investment-company-act-notices-and-orders{
  // exposed filter spacing
  .form-type-cshs {
   @include min-width($layout-max-width-medium) {
     max-width: fit-content;
   }
   .select-wrapper {
     margin-right: 0;
     display: block;
   }
  }

  @include min-width($layout-max-width-medium) {
    .form-type-textfield {
      max-width: 290px;
    }
  }
}
