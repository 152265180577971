//------------------------------------------------------------------------
// Tag group
//
// Note: USWDS doesn’t include a tag list, only as part of collections
//------------------------------------------------------------------------
.tag-group {
  $col-gap: 8px;
  $row-gap: 8px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-$col-gap);
  margin-top: rem(-$row-gap);

  .usa-tag {
    margin-left: rem($col-gap);
    margin-top: rem($row-gap);
  }
}
