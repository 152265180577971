//------------------------------------------------------------------------
// Ckeditor Styles
//------------------------------------------------------------------------
.cke {
  .form-textarea-wrapper & {
    margin-top: usa.units(1);
  }

  // Apply prose styles to CKEditor content
  &_editable {
    @include sec-prose;
    padding: rem(10px);
  }
}
