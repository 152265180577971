//------------------------------------------------------------------------
// Applys to ALL view
// Only put styles here that will apply to ALL views
// For updates to exposed filters see uswds_sec/assets/sass/components/drupal/_exposed_filters.scss
//------------------------------------------------------------------------

// If there is a header next to automatically generated feed icon, then add space
.view-header + .feed-icons {
  margin-top: 2rem;
}

.view-header {
  margin-bottom: 2rem;
}
