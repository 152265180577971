//------------------------------------------------------------------------
// Default node custom styles
//------------------------------------------------------------------------
.path-node {
  .field__label {
    font-weight: bold;
    font-family: $gotham-font-family;
  }
}

.path--404 .date-modified, .path--403 .date-modified {
  display: none;
}

// Set space on the Lead paragraph field
.field--name-field-lead-paragraph {
  // inherit margin from paragraph component
  margin-bottom: 0;
}
