//------------------------------------------------------------------------
// Topical Reference Guide view custom styling
//------------------------------------------------------------------------

.topical-reference-guide {
  .form--inline .usa-form-group {
    max-width: 170px;
  }

  .withdrawn {
    color: $c-red;
    font-weight: bold;
  }
}
