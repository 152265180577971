// Drupal block admin buttons
.region-tabs {
  $bp-hide: 620px;
  border-bottom: 1px solid $border-gray-light;
  border-top: 1px solid $border-gray-light;
  background-color: $button-gray-lighter;

  @include max-width($bp-hide - 1px) {
    display: none !important;
  }
}
