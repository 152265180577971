//------------------------------------------------------------------------
// Styles for Release Topper Block & Release type fields
/// @param {sting} $container
///   The class that is the direct parent of the two column fields
/// @param {sting} $quicklink
///   The class that contains the quick links field
/// @param {sting} $main
/// The class that contains the main section of the content
/// use @include layout-release-quicklinks($container, $main, $quicklink)
//------------------------------------------------------------------------

@use "../mixins/media-queries" as *;
@use "../functions/rem" as *;

@mixin layout-release-quicklinks($container, $main, $quicklink) {
  $quicklinks-mq: 910px;

  // Quick Links Area
  @include mq((min-width: $quicklinks-mq)) {
    #{$container} {
      display: flex;
      gap: 2rem;
    }
    #{$quicklink} {
      min-width: rem(270px);
    }
  }
  // mobile overrides
  @include mq((max-width: $quicklinks-mq - 1px)) {
    #{$container}:has(#{$quicklink}) #{$main} {
      margin-bottom: 2rem;
    }
  }

  // Setup spacing in main section
  #{$main} {
    container-type: inline-size;// set as container query wrapper
    flex-grow: 1;

    & > .field:not(:first-child) {
      margin-top: 2rem;
    }
    & > .field:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .field--name-field-customized-quick-links {
    font-size: .8rem;
  }
}
