//------------------------------------------------------------------------
// Callout Component
//------------------------------------------------------------------------
.callout {
  --bg-color: #{$callout-gray};
  --bg-color-border: #{$callout-gray-light};
  --border-width: 1px;
  @include scale(padding, (375px: 20px, 580px: 35px));
  background-color: var(--bg-color);
  border: var(--border-width) solid var(--bg-color-border);

  .usa-hint {
    color: #595D61;
  }

  & + & {
    margin-top: $layout-default-vert-margin;
  }

  .usa-prose & {
    &:where(:not(:first-child)) {
      margin-top: $layout-default-vert-margin;
    }

    &:where(:not(:last-child)) {
      margin-bottom: $layout-default-vert-margin;
    }
  }

  // temporarily comment out until type of callout boxes are defined by designer or PO
  // &--default-outline {
  //   --bg-color: transparent;
  //   --bg-color-border: #{$callout-gray-light};
  //   --border-width: 3px;
  // }

  // &--alternate {
  //   --bg-color: #{$callout-yellow};
  //   --bg-color-border: #{$callout-yellow-light};
  //   --border-width: 1px;
  // }

  // &--alternate-outline {
  //   --bg-color: transparent;
  //   --bg-color-border: #{$callout-yellow-light};
  //   --border-width: 3px;
  // }
}
