//------------------------------------------------------------------------
// Three-up related reports and publications
//
// Note: Cards are similar to usa-card but do not reuse their classes
//------------------------------------------------------------------------
.card-link {
  $bp-2-cols: 640px;
  $bp-3-cols: 915px;
  @include card-box-shadow;
  display: flex;
  flex-direction: column;

  @media print {
    break-inside: avoid;
  }

  // Note: Images should be converted to the correct aspect ratio by Drupal.
  //       We’re only using “aspect-ratio” to prevent layout shift during
  //       page load so it’s not imperative we polyfill for old browsers.
  //       https://caniuse.com/mdn-css_properties_aspect-ratio
  //
  // 1. Use object-fit just in case the image isn’t the correct aspect ratio,
  //    e.g. may be too small for Drupal to resize.

    &__img--image {
      img {
        display: block;
        object-fit: cover;// [1]
        aspect-ratio: 3 / 2;
      }
    }

    &__img--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 3 / 2;
    }

  &__body {
    @include card-horz-padding;
    display: flex;
    flex-direction: column;
    padding-bottom: rem(25px);
    padding-top: rem(25px);

    @include min-width($bp-2-cols) {
      flex-grow: 1;
    }

    &:first-child {
      padding-top: rem(32px);
    }
  }

  &__link {
    font-size: rem(15px);
    font-weight: bold;
    line-height: 1.2;

    // Since the link font-size is smaller than 16px, we need to
    // adjust the external/download icon vertical positioning.
    &:after {
      margin-top: rem(3px);
    }
  }

  &__description {
    font-size: rem(15px);
    line-height: 1.5;
    margin-top: rem(16px);
  }

  &__meta {
    font-size: rem(15px);
    margin-top: auto;// bottom align in grid layout
    padding-top: rem(20px);

    :where(:not(:first-child)) {
      margin-top: rem(16px);
    }
  }
}



// .card-link_image_type--image .card-link__img {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: red;
//   aspect-ratio: 3 / 2;
// }

// .card-link_image_type--icon .card-link__img {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: green;
//   aspect-ratio: 3 / 2;
// }