//------------------------------------------------------------------------
// Meetings and Events view custom styling
//------------------------------------------------------------------------

// Hides the first horizontal line for the collection item if the exposed filter
// is present.

.view-callout {
  .view-content .usa-collection__item:nth-child(1) {
    border-top: none;
  }
}

.upcoming-views-block {
   // if the view using using a callout on the exposed filters, update the styles a bit
  .callout {
    margin-bottom: 2rem;
  }

  .view-header {
    margin-bottom: 1rem;
  }

  // alter fields in the exposed filters
  .form--inline {
    grid-template-columns: 340px 1fr 1fr;
    margin-top: 0;
    display: grid;
    row-gap: 1rem;

    .js-form-item-combine {
      display: grid;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
    }

    .js-form-item-field-person-target-id {
      grid-column-start: 1;
      grid-row-start: 2;
      input.form-text {
        width:  100%;
      }
    }

    .js-form-item-field-event-type-value-1 {
      grid-column-start: 2;
      grid-row-start: 1;
    }

    .js-form-item-event-year {
      grid-column-start: 3;
      grid-row-start: 1;
    }

    .form-actions {
      grid-row-start: 2;
    }

    .usa-form-group {
      max-width: none;
    }

    @include max-width($layout-max-width-medium) {
      grid-template-columns: 1fr;

      .js-form-item-combine {
        grid-column-start: 1;
        grid-column-end: 1;
      }

      .js-form-item-field-event-type-value-1 {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      .js-form-item-event-year {
        grid-column-start: 1;
        grid-row-start: 3;
      }

      .form-actions {
        grid-row-start: 4;
      }
    }
  }
}
