//
// Notice of Covered Actions
// VIEW NAME: WB Covered Actions List Page
// List Articles with a type of "Award Claim"
//
// --------------------------------------------------

.block-views-blockduplicate-of-wb-covered-actions-list-page-block-1 {
  .views-field-body {
    & > :first-child  {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

