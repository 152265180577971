//------------------------------------------------------------------------
// Default text element styles
//
// Note: These styles apply globally. Any styles that should _only_ apply
//       to prose should go in /mixins/_prose.scss
//------------------------------------------------------------------------
// Apply default link styles
// Note: Use :where() to reduce specificity to zero, allowing custom
//       component styles to override more easily
:where(a) {
  @include link-base;
}

// Automatically append external and download icons
:where(a:not(.icon-text, .card-link__img, .file-button)) {
  @include link-add-icons;
}

// Tables
// https://designsystem.digital.gov/components/table/
// https://github.com/uswds/uswds/blob/develop/packages/uswds-core/src/styles/mixins/general/table.scss
// NOTE: USWDS adds support for the “usa-table” class and its variations
table {
  @include usa.usa-table;
}

// Note: USWDS doesn’t include styles for <tfoot>
tfoot {
  th,
  td {
    background-color: usa.$theme-table-header-background-color;//$table-header;
  }

  th {
    font-weight: bold;
  }
}

// Horizontal rule
hr, hr:last-child {
  border-bottom: 0;// reset browser default
  border-top: 1px solid currentColor;
  display: block;
  margin-bottom: 4em;
  margin-top: 4em;
}

// Note: Don’t use :where() since USWDS will override it
code,
kbd,
samp,
pre,
var {
  font-family: $mono-font-family;
  font-size: rem(14px);
}

kbd {
  background-color: $button-gray-light;
  border-radius: 4px;
  border: solid 1px rgba($c-gray-lighter, 0.2);
  box-shadow: 0 1px 1px rgba(#000, 0.15), inset 0 1px 0 0 rgba(#fff, 0.7);
  display: inline-block;
  line-height: 1;
  padding: rem(3px 5px);
}

code,
var {
  background-color: $bg-gray-light;
  border-radius: 4px;
  padding: rem(2px 4px);
  color: #6a1010;
}

// Preformatted text
// Note: Used for code snippets in the style guide (/sec-styleguide)
pre {
  line-height: 1.25;
  white-space: pre-wrap;
  word-break: break-word;

  code,
  var {
    background-color: transparent;
    padding: 0;
  }
}

dl {
  line-height: 1.5;
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}

dt {
  font-weight: bold;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

dd {
  margin-left: 1em;
}
