//------------------------------------------------------------------------
// Override legacy tablesort styles
//
// Original CSS: /themes/contrib/stable/css/system/components/tablesort.module.css
//------------------------------------------------------------------------
// Note: Duplicate the top-level selector to increase specificity so we
//       can override the original CSS without needing !important.
.tablesort.tablesort {
  $icon-size: 24px;
  height: rem($icon-size);
  vertical-align: middle;
  width: rem($icon-size);

  &--asc {
    background-image: svg-url('arrowUp', (fill: $c-black));
    @media print {
      background: none;
    }
  }

  &--desc {
    background-image: svg-url('arrowDown', (fill: $c-black));
    @media print {
      background: none;
    }
  }
}
