//------------------------------------------------------------------------
// View: Speeches Statements List Page
//------------------------------------------------------------------------

.view-speeches-statements-list-page {
  // Speaker Column
  .views-field-field-person {
    p {
      margin: 0;
    }
  }
}