//------------------------------------------------------------------------
// Custom SEC prose styles mixin
//
// NOTE: We commented out the default prose style in _uswds-custom.scss
//       since they were too opinionated and not fully configurable.
//       Instead, we wrote our own custom prose styles below. Any element
//       styles not included below are likely global styles that can be
//       found in normalize.css (see below), _reset.scss, or _base.scss.
//
// NOTE: USWDS uses normalize.css which includes some minor type styles.
//       https://github.com/necolas/normalize.css/
//
// https://designsystem.digital.gov/components/prose/
// https://github.com/uswds/uswds/blob/develop/packages/usa-prose/src/styles/_usa-prose.scss
//------------------------------------------------------------------------
@use "../vars/layout-vars" as *;
@use "../functions/rem" as *;
@use "typography" as *;
@use "uswds-settings" as usa;

@mixin sec-prose($use-child-selector: false) {
  // Wrap selector in :where() to reduce specificity, making it easier for
  // custom blocks to override these styles when needed.
  @at-root :where(#{&}) {
    // Add child selector (>) if $use-child-selector is true. This prevents
    // thse styles from applying to custom components, which typically
    // have at least one wrapper element around any wysiwyg content.
    #{if($use-child-selector, '>', '&')} {
      // For selectors that always need the child selector, we have to check
      // if $use-child-selector is true to avoid duplicating it (e.g. “> >”)
      $child-selector: if($use-child-selector, '', '>');

      // Remove top margin on first child (for headings, lists, tables, etc.)
      #{$child-selector} *:first-child {
        margin-top: 0;
      }

      // Remove bottom margin on last child (for paragraphs, headings, lists, tables, etc.)
      #{$child-selector} *:last-child {
        margin-bottom: 0;
      }

      // Paragraphs
      p {
        line-height: 1.5;
        margin-bottom: 0.75em;
        margin-top: 0.75em;
      }

      // Headings
      h2 {
        @include h2($include-margin: true);
      }

      h3 {
        @include h3($include-margin: true);
      }

      h4 {
        @include h4($include-margin: true);
      }

      h5 {
        @include h5($include-margin: true);
      }

      h6 {
        @include h6($include-margin: true);
      }

      // Remove top margin on adjacent headings
      // Note: We’re only considering accessible heading combinations
      // https://github.com/postcss/autoprefixer/issues/902#issuecomment-742555755
      h2 + h2,
      h2 + h3,
      h3 + h3,
      h3 + h4,
      h4 + h4,
      h4 + h5,
      h5 + h5,
      h5 + h6,
      h6 + h6 {
        margin-top: 0;
      }

      // Blockquote margins
      blockquote {
        &:where(:not(:first-child)) {
          margin-top: $layout-default-vert-margin;
        }

        &:where(:not(:last-child)) {
          margin-bottom: $layout-default-vert-margin;
        }
      }

      // Lists
      ol,
      ul {
        $list-padding-left: 3ch;
        $list-item-margin: 0.25em;
        margin-bottom: 1em;
        margin-top: 1em;
        padding-left: $list-padding-left;

        ul,
        ol {
          margin-bottom: $list-item-margin;
          margin-top: $list-item-margin;
          padding-left: $list-padding-left;// repeated since $prepend-selector could be “>”
        }

        li {
          line-height: 1.5;

          &:not(:first-child) {
            margin-top: $list-item-margin;
          }
        }
      }

      // Unordered list styles
      ul,
      ol ul {
        list-style: disc;

        ul {
          list-style-type: circle;

          ul {
            list-style-type: square;

            ul {
              list-style-type: disc;
            }
          }
        }
      }

      // Ordered list styles
      ol,
      ul ol {
        list-style: decimal;

        ol {
          list-style: lower-alpha;

          ol {
            list-style: lower-roman;

            ol {
              list-style: decimal;
            }
          }
        }
      }
    }
  }
}
