//
// SALI/IDD/BAD ACTOR List Page
// View name: Individual Defendants
//
// --------------------------------------------------

.view-bad-actor{
	$ba-field-spacing: 4px;

	.bad-actor-subtitle{
		font-size: rem(18px);
	}

	// exposed filters

	.view-filters {
		--bg-color: #{$callout-gray};
		--bg-color-border: #{$callout-gray-light};
		--border-width: 1px;
		@include scale(padding, (375px: 20px, 580px: 35px));
		background-color: var(--bg-color);
		border: var(--border-width) solid var(--bg-color-border);
		margin-bottom: 2rem;
		.usa-alert--error {
			margin-bottom: 10px;
		}
	}

	.form--inline {
		margin-top: 0;
	}


	// Items
	.views-row +.views-row {
		margin-top: rem(24px);
		border-top: 1px solid $border-gray;
		padding-top: rem(24px);
	}

	.views-field {
		width: 100%;
		display: table;
		line-height: 1.2;
		margin: $ba-field-spacing 0;

		.views-label {
			display: table-cell;
			width: 195px;
			font-weight: bold;
		}

		div.field-content {
			display: table-cell;
		}
	}


	.card-title {
		margin-bottom: rem(20px);
		color: $c-navy;
	}

	.views-field-field-related-documents ul {
		list-style: none;

		li {
			margin: $ba-field-spacing 0;

			.paragraph--view-mode--comma-seperated div {
					display: inline-block;
					margin-bottom: 0px;
			}
		}
	}

	.no-results {
			padding: rem(24px);
			text-align: center;
			font-size: 1.2em;
			max-width: 590px;
			margin: 0 auto;
	}
}

// Block displayed at the bottom of the page
#block-uswds-sec-abouttheidd {
	margin-top: 2rem;
}