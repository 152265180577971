//------------------------------------------------------------------------
// Regulatory Files node details page
//------------------------------------------------------------------------
.node--type-rule {
  // -------------------------------------
  // Main Section
  .node-details-layout__main-region {
    h2 {
      margin-bottom: rem(20px);
    }
  }

  .field--name-field-related-topics {
    @extend .tag-group;
  }

  .sro-combined {
    display: none;
    &:has(.sro-organization-label) {
      margin-bottom: $field-small-vert-margin;
      display: block;
    }
  }


  // Comments section
  .public-comments {
    margin-bottom: $field-default-vert-margin;
    .field {
      margin-bottom: 0;
    }
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  // Overview Section
  .rule-overview {
    margin-bottom: $field-default-vert-margin;
  }

  // -------------------------------------
  // Accordions
  .usa-accordion__content {
    .field {
      margin-bottom: rem(5px);
    }

    .text-formatted p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  // -------------------------------------
  // Resources
  .side-resources {
    .field__item, .field {
      margin-bottom: rem(5px);
    }
  }
  // -------------------------------------
  // Details

  .side-details {
    .field {
      margin-bottom: rem(5px);
    }

    .text-formatted p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
