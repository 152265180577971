//------------------------------------------------------------------------
// Comment Form node custom styles
//------------------------------------------------------------------------
.webform-submission-ruling-comments-form-form [data-webform-key="confirm_page"] {

  .usa-label {
    margin-top: .5rem;
  }

  hr {
    margin-bottom: 2em;
    margin-top: 2em;
  }

}