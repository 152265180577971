//------------------------------------------------------------------------
// Icon and text component
//------------------------------------------------------------------------
.icon-text {
  font-family: $gotham-font-family;
  font-size: rem(14px);
  font-weight: $gotham-medium;
  display: flex;
  align-items: center;

  &:where(a) {
    width: fit-content;// otherwise “flex” will cause it to be 100% wide
  }

  // Note: Since we can’t get each individual icon’s viewBox dimensions
  //       in the template, the size of the SVG element won’t match the
  //       <use> element. This means the height and width below must be
  //       set larger than the desired size of the icon itself.
  .usa-icon {
    --icon-size: #{rem(27px)};// use var so we can override more easily
    fill: $c-blue;
    flex-shrink: 0;
    height: var(--icon-size);
    margin-right: rem(5px);
    vertical-align: middle;
    width: var(--icon-size);
  }

  .address_org {
    font-weight: bold;
  }
}
