//
// SEC Videos Grid
// View name: SEC Videos Media
// --------------------------------------------------

// override spacing
.sec-videos-view {
  .view-filters {
    margin-bottom: rem(40px);
  }
  .usa-card-group {
    .views-row {
      display: flex;
    }
  }
}

.media--type-video-media {
  .card-link__img--image img {
    aspect-ratio: 16 / 9;
  }
}