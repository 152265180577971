//------------------------------------------------------------------------
// Custom alert styles and overrides
// https://designsystem.digital.gov/components/alert/
//------------------------------------------------------------------------
.usa-alert {
  &__heading:only-child {
    margin-bottom: 0;
  }

  &__body {
    &:before {
      // Since icons rely on a mask and background-color, Chrome/Edge users on Windows
      // need to enable background graphics in print settings. However, it’s unlikely
      // most users will do this and will instead see a black box instead of the icon.
      // Since we’re already showing the full URL in print view, we’re hiding the icon.
      @media print {
        display: none !important;
        // Using box-shadow instead of a background color worked on Macs but not Windows.
        // box-shadow: inset 0 0 0 2em #000 !important;// use spread value > icon size
      }
    }
  }
}

.path-frontpage .usa-alert {
  position: relative;
  z-index: 96;
}
