/* Create a mixin for comma-separated list items */
/* that accepts an element reference and separator */

@mixin separated-list($parentEl, $el, $sep) {
  /* Reference the passed element */
  #{$parentEl} {
    display: flex;
    list-style: none;

    & > #{$el} {
      &:not(:last-child) {
        &:after {
          /* Reference the passed separator */
          content: "#{$sep}";
          margin-right: .1em;
        }
      }
    }
  }
}

// usage
// @include separated-list('.field--name-field-release-number','.field__item', ',\00a0');