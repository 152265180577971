//------------------------------------------------------------------------
// Releases via Taxonomy view custom styling
//------------------------------------------------------------------------

.view-releases-via-taxonomy {
  // quicklinks and info area at the top of views
  @include layout-release-quicklinks('.content', '.content__main', '.content__quick');

  // Table
  .views-field-field-publish-date {
    width: rem(150px);
  }
}
