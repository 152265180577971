//------------------------------------------------------------------------
// Field Group Helpers
// Helper classes can be added to a field group
//------------------------------------------------------------------------

// class that can be added to a field group that will cause the fields in that group to be separated by a bar
.bar-separated {
  display: flex;

  & > div {
    border-right: 2px solid $border-gray-dark;
    padding-right: 15px;
    margin-right: 15px;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }
}