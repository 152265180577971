//------------------------------------------------------------------------
// Breadcrumb overrides
// https://designsystem.digital.gov/components/breadcrumb/
//
// TODO: Move to breadcrumb pattern folder once its created
//------------------------------------------------------------------------
.usa-breadcrumb {
  $bp-desktop: 480px;// USWDS desktop breakpoint, shows single link below this
  background-color: transparent;// override USWDS default
  color: var(--theme-text-color, $c-default);
  font-size: rem(14px);
  margin-bottom: rem(32px);
  padding-bottom: 0;// override USWDS default
  padding-top: 0;// override USWDS default

  // Something is hiding the breadcrumbs in print mode but it’s unclear what.
  // However, adding this fixes the issue.
  @media print {
    display: block !important;
  }

  // SEC requested we hide the breadcrumbs in mobile layout
  @include max-width($layout-nav-bp - 1px) {
    display: none;
  }

  &__list {
    $vert-margin: 8px;
    margin: rem(-$vert-margin) 0 0;// override USWDS default
    padding: 0;// override USWDS default

    // Note: Items are set to “display: inline” to support truncation
    &-item {
      letter-spacing: 0.2px;

      .usa-breadcrumb--wrap & {
        margin-top: rem($vert-margin);
        white-space: normal;// override USWDS default
        display: inline;
      }

      // Custom separator
      &:not(:last-child):after {
        @supports (mask: url("")) {
          background-color: var(--theme-text-color, $c-gray-light);
          margin-left: rem(3px);
          margin-right: rem(2px);
          mask-image: svg-url('slash', (fill: #000));

          // Since icons rely on a mask and background-color, Chrome/Edge users on Windows
          // need to enable background graphics in print settings. However, it’s unlikely
          // most users will do this and will instead see a black box instead of the icon.
          // Due to this, we’re using a “/” character in print instead of the default icon.
          @media print {
            background-color: transparent;
            color: currentColor;
            content: '/';
            height: auto;
            mask-image: none;
            position: static;
            width: auto;
          }
        }
      }
    }
  }

  &__link {
    @include link-underline;

    &,
    &:visited {
      color: currentColor;
    }

    // Use thinner underline when supported
    @supports (text-decoration-thickness: 1px) {
      text-decoration-color: currentColor;
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
  }
}
