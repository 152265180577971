//------------------------------------------------------------------------
// View: Investment Company Act Deregistration
//------------------------------------------------------------------------

.view-id-investment_company_act_deregistration {
  // Details Column
  .views-field-nothing {
    ul {
      margin-bottom: 0;
    }
  }
}
