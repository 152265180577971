//
// Market Structure Data Downloads
// VIEW NAME: Market Structure Data List Page
// List Articles with a type of "Data" Subtype "Market Structure"
//
// --------------------------------------------------

.view-market-structure-data-list-page {
  .views-field-field-list-page-det-secarticle {
    & > :first-child  {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

