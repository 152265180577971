//------------------------------------------------------------------------
// CTA card with image and sidebar
//------------------------------------------------------------------------
.card-sidebar {
  $bp-end-scale: 460px;
  $bp-sidebar: 540px;// for container queries
  $bp-sidebar-fallback: 680px;// for fallback media queries
  @include card-box-shadow;
  background-color: $bg-default;

  // Use container query so component will adjust when in a narrow column
  @container (min-width: #{rem($bp-sidebar)}) {
    display: flex;
  }

  // Fallback to media query for old browsers
  // https://caniuse.com/css-container-queries
  @supports not (container-type: inline-size) {
    @include min-width($bp-sidebar-fallback) {
      display: flex;
    }
  }

  &__main {
    padding: rem(30px 22px 22px);

    @container (min-width: #{rem($bp-sidebar)}) {
      flex-grow: 1;
      padding: rem(48px 36px 42px 45px);
    }

    @supports not (container-type: inline-size) {
      @include min-width($bp-sidebar-fallback) {
        flex-grow: 1;
        padding: rem(48px 36px 42px 45px);
      }
    }
  }

  &__image_wrapper {
    max-width: rem(326px);
    img {
      display: block;
    }
  }

  // Note: Scale values using container query units for modern browsers
  &__text {
    font-size: scale-clamp((375px: 16px, $bp-end-scale: 20px), $units: cqw);
    line-height: 1.4;
    margin-top: scale-clamp((375px: 18px, $bp-end-scale: 36px), $units: cqw);
    max-width: rem(280px);

    // Fallback to viewport width units for older browsers
    // https://caniuse.com/css-container-query-units
    @supports not (container-type: inline-size) {
      font-size: scale-clamp((375px: 16px, $bp-end-scale: 20px));
      margin-top: scale-clamp((375px: 18px, $bp-end-scale: 36px));
    }
  }

  &__footer {
    margin-top: scale-clamp((375px: 26px, $bp-end-scale: 40px), $units: cqw);

    @supports not (container-type: inline-size) {
      margin-top: scale-clamp((375px: 26px, $bp-end-scale: 40px));
    }

    .usa-button {
      width: auto;// override USWDS default
    }
  }

  // NOTE: Wrapper has the “usa-prose” class
  &__sidebar {
    background-color: $bg-green-light;
    padding: rem(22px 22px 30px);

    @container (min-width: #{rem($bp-sidebar)}) {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: center;
      min-width: rem(230px);
      padding: rem(33px);
      width: 35%;
    }

    @supports not (container-type: inline-size) {
      @include min-width($bp-sidebar-fallback) {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: center;
        min-width: rem(230px);
        padding: rem(33px);
        width: 35%;
      }
    }

    &__heading {
      font-size: rem(13px);
      font-weight: bold;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    &__list {
      font-size: scale-clamp((375px: 14px, $bp-end-scale: 16px), $units: cqw);

      @supports not (container-type: inline-size) {
        font-size: scale-clamp((375px: 14px, $bp-end-scale: 16px));
      }
    }
  }
}
