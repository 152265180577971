//------------------------------------------------------------------------
// Spacing between Components
//
// NOTE: Use :where() to keep specificity low so we can override if needed
//------------------------------------------------------------------------

// Layout Builder pages components
:where(.layout-section) {
  // Blocks within a column layout region (section column)
  :where(.layout__region > .block + .block) {
    margin-top: $layout-default-vert-margin;
  }

  // Components within the same block wrapper (multiple components in the same block)
  :where(.field__item + .field__item) {
    margin-top: $layout-default-vert-margin;
  }
}

// Nodes that use the "Content" field to display components
:where(.field--name-field-content) {
  // Components within the same block wrapper
  :where(.field__item + .field__item) {
    margin-top: $field-component-vert-margin;
  }
}

//------------------------------------------------------------------------
// Overrides for between component spacing
//------------------------------------------------------------------------

// Override collection spacing when used as a component
// Fix spacing and border
.field__item {
  // If not the last child, add an border at the bottom
  &:not(:last-child) {
    .usa-collection__item:last-child {
      border-bottom: 1px solid $border-gray;
      padding-bottom: 1.5rem;
    }
  }
  // If not the first child, remove extra space between items, Component spacing above handles this
  &:not(:first-child) {
    .usa-collection {
      margin-top: 0;
    }
  }
}
