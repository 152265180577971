//------------------------------------------------------------------------
// Icon and text component
//------------------------------------------------------------------------
.icon-text-row {
  $col-gap: 25px;
  $row-gap: 12px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: $row-gap;
  column-gap: $col-gap;

  // Override default styles
  .icon-text {
    font-family: $body-copy-font;
    font-size: rem(16px);
    font-weight: normal;
    line-height: 1.5em;
  }

  .usa-icon {
    --icon-size: #{rem(24px)};
    margin-right: rem(8px);
  }
}
