// -------------------------------------------------------------------------
// Print Styles (based on HTML5 Boilerplate)
//
// Note: Use @extend %hide-print to hide individual modules
// stylelint-disable:disable no-important, no-color-literals
// -------------------------------------------------------------------------
@media print {
  :root {
    --theme-button-bg-color: #fff;
    --theme-button-color: #000;
    --theme-button-icon-color: #000;
    --theme-color: #000;
    --theme-link-active-color: #000;
    --theme-link-color:  #000;
    --theme-link-visited-color:  #000;
    --theme-outline-button-color: #000;
    --theme-text-color:  #000;
  }

  // Page margins
  @page {
    margin: 0.75in 0.75in 1in;
  }

  *,
  *:before,
  *:after {
    background-color: transparent !important;
    color: #000 !important;// Black prints faster
    text-shadow: none !important;
  }

  main {
    border: 0 !important;
  }

  a,
  a:link,
  a:visited {
    color: #000;
    text-decoration: underline;
  }

  // Add the URL after the link text, but ignore the following:
  // - mailto, tel, and fax
  // - jump links (e.g. href="#")
  // - links to the homepage
  // - buttons
  // - breadcrumbs
  // - profile photo download link
  //
  // 1. Override external/download icon styles
  a[href]:not([href^="#"], [href^="mailto:"], [href^="tel:"], [href^="fax:"], [href="/"], [href="https://www.sec.gov"], [href="https://www.sec.gov/"], [class*="usa-button"], [class*="usa-breadcrumb__link"], [class*="image-link__link"], [data-drupal-facet-widget-element-class="facets-checkbox"]) {
    &:after {
      @include break-word;
      background-color: transparent !important;// [1]
      box-shadow: none !important;// [1]
      content: "\00a0(" attr(href) ")";// “\00a0” is a non-breaking space
      font-size: rem(14px);
      font-style: normal;
      font-weight: normal;
      height: auto !important;// [1]
      mask: none !important;// [1]
      position: static !important;
      width: auto !important;// [1]
    }

    // Links inside of “.usa-prose” automatically get external/download icons
    // so we need to remove them in order to add the URL after the link text.
    .usa-prose & {
      display: inline !important;
      padding-right: 0;

      &:after {
        box-shadow: none !important;
        display: inline !important;
        height: auto !important;
        margin-top: 0;
        mask: none !important;
        position: static;
        width: auto !important;
      }
    }

    // Links inside a table head should not show URL.
    th & {
      &:after {
        content: none;
      }
    }
  }

  // Hide URLs after linked images (these are typically redundant)
  @supports selector(:has(*)) {
    a[href]:has(> img:only-child, > svg:only-child) {
      &:after {
        display: none !important;
      }
    }
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  img {
    max-width: 100%;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  .usa-table th {
    padding: .5rem 1rem !important;
    a {
      padding: 0 !important;
    }
  }

  p,
  h2,
  h3 {
    orphans: 2;
    widows: 2;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .hide-for-print,
  #toolbar-administration,
  .env-indicator-text {
    display: none !important;
  }

  [class*="bg-"] {
    background-color: #fff !important;
  }

  // Hide Drupal tabs
  .region-tabs {
    display: none !important;
  }
}

// Optional: High-resolution device print styles
// @media print,
//   (-webkit-min-device-pixel-ratio: 1.25),
//   (min-resolution: 1.25dppx),
//   (min-resolution: 120dpi) {
// }
