//------------------------------------------------------------------------
// Global helper classes not included in USWDS
//------------------------------------------------------------------------
// Visually hide text whie still making it accessible to assistive devices
// Note: We’re not using the sr-only() mixin from USWDS since it doesn’t
//       address all edge cases (uses older negative text-indent method)
// https://github.com/uswds/uswds/blob/main/packages/uswds-core/src/styles/mixins/general/screen-reader.scss
.visually-hidden {
  @include visually-hidden;
}

// Prevent text from wrapping
.nowrap {
  @include min-width(320px) {
    display: inline-block;// necessary for nested inline elements
    text-decoration: inherit;// don’t break link styles
    white-space: nowrap !important;

    @supports (text-decoration-color: #000) {
      text-decoration-color: inherit;// Safari fix
    }
  }
}

// This class is used by legacy content
// https://jira.ad.sec.gov/browse/OSSS-26045
.no-bullet {
  list-style: none;
  padding-left: 0;
}
