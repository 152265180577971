//------------------------------------------------------------------------
// Heading and custom text syles
//
// Note: Only styles that could be used outside of a “usa-prose” wrapper
//       should be included here (see _prose.scss)
//------------------------------------------------------------------------
@use "../vars/color-vars" as *;
@use "../vars/font-vars" as *;
@use "../vars/layout-vars" as *;
@use "../functions/rem" as *;
@use "media-queries" as *;
@use "scale" as *;

@mixin h2($color: $c-black, $include-margin: false) {
  @include scale(font-size, (360px: 22px, $layout-text-scale-end-bp: 24px));
  color: var(--theme-text-color, $color);
  font-family: $gotham-font-family;
  font-weight: bold;
  line-height: 1.175;

  @if $include-margin {
    margin-bottom: rem(24px);
    margin-top: rem(50px);
  }
}

@mixin h3($color: $c-black, $include-margin: false) {
  @include scale(font-size, (360px: 20px, $layout-text-scale-end-bp: 22px));
  color: var(--theme-text-color, $color);
  font-weight: bold;
  line-height: 1.175;

  @if $include-margin {
    margin-bottom: rem(20px);
    margin-top: rem(36px);
  }
}

@mixin h4($color: $c-black, $include-margin: false) {
  color: var(--theme-text-color, $color);
  font-size: rem(16px);
  font-weight: bold;
  line-height: 1.175;

  @if $include-margin {
    margin-bottom: rem(20px);
    margin-top: rem(38px);
  }
}

@mixin h5($color: $c-black, $include-margin: false) {
  color: var(--theme-text-color, $color);
  font-size: rem(15px);
  font-weight: bold;
  line-height: 1.175;

  @if $include-margin {
    margin-bottom: rem(16px);
    margin-top: rem(26px);
  }
}

@mixin h6($color: $c-black, $include-margin: false) {
  color: var(--theme-text-color, $color);
  font-size: rem(13px);
  font-weight: normal;
  letter-spacing: rem(0.5px);
  line-height: 1.175;
  text-transform: uppercase;

  @if $include-margin {
    margin-bottom: rem(16px);
    margin-top: rem(26px);
  }
}

// Default page title
@mixin page-title($color: $c-navy) {
  @include scale(font-size, (375px: 26px, $layout-text-scale-end-bp: 36px));
  @include scale(letter-spacing, (320px: -1px, 640px: 0));// reduce text wrapping in mobile
  color: var(--theme-text-color, #{$color});
  font-family: $gotham-font-family;
  font-weight: bold;
  line-height: 1.2;

  .has-sidebar & {
    @include scale(font-size, (
      $layout-sidenav-bp: 32px,
      $layout-sidenav-bp-wide: 36px
    ), $initial: false);
  }

  @include min-width(640px) {
    line-height: 1.1;
  }

  // Some pages have super long titles, the font size is reduces for these pages. (over 125 chars)
  &--long-title {
    font-size: 1.5rem;
  }
}

// Small uppercase heading used for supertitle and sidebar headings
@mixin upper-heading($color: $c-navy) {
  color: var(--theme-text-color, #{$color});
  font-family: $gotham-font-family;
  font-size: rem(16px);
  font-weight: $gotham-medium;
  letter-spacing: rem(0.5px);
  line-height: 1;
  text-transform: uppercase;
}

// Larger body copy used for intro block
@mixin large-body-copy($color: $c-black) {
  @include scale(font-size, (375px: 19px, $layout-text-scale-end-bp: 26px));
  color: var(--theme-text-color, $color);
  font-weight: 300;
  line-height: 1.175;
}
