//------------------------------------------------------------------------
// Homepage template styles
//------------------------------------------------------------------------
.path-frontpage-plus {
  .main-content {
    @include default-padding;
    @include default-page-bottom;

    // Remove the default page borders
    @include min-width($layout-max-width-global) {
      border-left: 0;
      border-right: 0;
    }

    &__main {
      container-type: inline-size;// act as container for container queries
      max-width: rem($layout-max-width-wide);
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Makes specific section on homepage have equal height blocks
// See https://jira.ad.sec.gov/browse/OSSS-30240 and assets/js/modules/homepage-same-height-row.js
.homepage-same-height-row {
  .block, .block > div {
    height: 100%;
  }

  .layout__region--first {
    @include min-width(1066px) {
      flex: 1 0 40%;
    }
  }
}