//------------------------------------------------------------------------
// Modal overrides
// https://designsystem.digital.gov/components/modal/
//------------------------------------------------------------------------
.usa-modal {
  $selector: &;
  border-radius: 4px;
  position: relative;

  // Prevent FOUC by hiding modal content before the JS has initialized.
  .js & {
    display: none;
  }

  // Once the modal JS runs, it moves the original markup to the
  // end of the <body> inside of a “.usa-modal-wrapper” wrapper.
  .js .usa-modal-wrapper & {
    display: inline-block;// reset to USWDS default
  }

  // Hide toggle button when JS is disabled
  @at-root .no-js [data-open-modal] {
    display: none !important;
  }

  // Custom styles for large variation
  &--lg {
    max-width: rem(1052px);
    padding: rem(40px 32px 32px);

    .usa-modal__heading {
      font-size: 1.5rem;
    }

    #{$selector}__content {
      padding-top: 0;
    }

    #{$selector}__main {
      max-width: none;
      padding: 0 !important;

      iframe {
        display: block;
        width: 100%;
      }
    }
  }

  // Close button
  // Note: SEC’s default button styles conflict with the modal button
  //       so we removed the “usa-button” class from the markup.
  &__close {
    --button-width: #{rem(44px)};
    border: 0;
    height: var(--button-width);
    margin: 0;
    padding: rem(5px);
    position: absolute;
    right: 0;
    top: 0;
    width: var(--button-width);

    .no-js & {
      display: none;
    }
  }

  // Override card styles
  .usa-card__body {
    padding: 0;
  }

  // Override USWDS defaults
  &-wrapper.is-visible,
  &-overlay {
    @media print {
      position: static !important;
    }
  }
}
