//------------------------------------------------------------------------
// View Tables
//------------------------------------------------------------------------
.usa-table.views-table {
  width: 100%;

  // If a table has 4+ columns allow all header cell text to wrap (e.g. /rules-regulations/investment-company-act-notices-orders)
  tr:has(th:nth-last-child(n+4)) th {
    white-space: normal;
  }

  // Prevent column headers from wrapping by default
  // (but only when < 4 columns, see above)
  th {
    white-space: nowrap;

    // For sortable columns, move padding from <th> to <a> so we can position
    // the up & down icon. Unfortunately, this is the only way we can tell if
    // a column is sortable given the current markup.
    // Note: Support for :has() isn’t universal yet https://caniuse.com/css-has
    &:has(a) {
      padding: 0;
      position: relative;// for link overlay

      a {
        padding: 0.5em 1.6em 0.5em 1em;
        min-width: 150px;

        // Reset the right padding on the currently sorted column since it
        // has an inline SVG icon.
        &:has(*) {
          padding-right: 0;
        }

        // Link overlay to make entire <th> clickable when the text wraps
        &:after {
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }

    // Remove default link styles on sortable column headings and right align
    // the arrow icon.
    a {
      align-items: center;
      color: $c-default;
      display: flex;
      justify-content: space-between;
      text-decoration: none;

      // Add up & down arrow background image to unsorted column headers
      // (i.e. links without any child elements)
      &:not(:has(*)) {
        background-image: svg-url('arrowUpDown', (fill: $c-black));
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 24px auto;
        @media print {
          background: none;
        }
      }
    }

    // Note: The sortable column headers on sec.gov/data are missing missing
    //       the link element and arrow icon but still have the blue background.
    //       We’re resetting the background for now but these styles should be
    //       removed once this is fixed.
    // TODO: Update table header cell markup to add missing link and icon
    &.is-active {
      &:not(:has(a)) {
        background-color: usa.$theme-table-header-background-color;
      }
    }
  }

  // Some tables have a single column with a lot more content than the others
  // (e.g. /rules-regulations/investment-company-act-deregistration-notices-orders) which makes it hard to scan. To account for this,
  // we’re top aligning cell text.
  td {
    vertical-align: top;
  }

  // Set content in cells to be slightly smaller to account for the amount of data in these tables.
  :where(tbody) {
    font-size: .9rem;
  }

  // Prevent dates from wrapping
  .views-field-field-publish-date,
  .views-field-field-date {
    time {
      white-space: nowrap;
      @media print {
        white-space: normal;
      }
    }
  }

  // Prevent numbers from wrapping
  td.views-field-field-release-number {
    max-width: 150px;
    white-space: nowrap;

    // Add styling to the new tag
    .new-tag {
      background-color: $c-blue;
      border-radius: 0.25rem;
      color: $bg-default;
      text-align: center;
      margin-top: 0.4rem;
      padding: 0.4rem 0;
    }

    a {
      white-space: nowrap;
      @media print {
        white-space: normal;
      }
    }

    @media print {
      white-space: normal;
    }
  }
}

.view-other-rules .usa-table.views-table td.views-field-field-release-number {
  white-space: normal;
}
