//------------------------------------------------------------------------
// CTA card with icon
//------------------------------------------------------------------------
// Note: Scale values using container query units for modern browsers
.card-icon {
  $bp-end-scale: 400px;
  @include card-horz-padding;
  padding-bottom: scale-clamp((375px: 25px, $bp-end-scale: 54px), $units: cqw);
  padding-top: scale-clamp((375px: 30px, $bp-end-scale: 38px), $units: cqw);

  // Fallback to viewport width units for older browsers
  // https://caniuse.com/css-container-query-units
  @supports not (container-type: inline-size) {
    padding-bottom: scale-clamp((375px: 25px, $bp-end-scale: 54px));
    padding-top: scale-clamp((375px: 30px, $bp-end-scale: 38px));
  }

  &__heading {
    font-size: scale-clamp((375px: 22px, $bp-end-scale: 33px), $units: cqw);
    align-items: center;
    display: flex;
    font-family: $gotham-font-family;
    justify-content: space-between;
    font-weight: bold;
    line-height: 1;

    @supports not (container-type: inline-size) {
      font-size: scale-clamp((375px: 22px, $bp-end-scale: 33px));
    }

    // Note: “.usa-icon” includes “height: 1em; width: 1em;” from USWDS
    // Note: Since we can’t get each individual icon’s viewBox dimensions
    //       in the template, the size of the SVG element won’t match the
    //       use element, so the styles may not match Figma _exactly_.
    .usa-icon {
      fill: $bg-yellow;
      flex-shrink: 0;
      height: 100%;
      margin-left: scale-clamp((375px: 16px, $bp-end-scale: 40px), $units: cqw);
      max-height: scale-clamp((375px: 40px, $bp-end-scale: 74px), $units: cqw);
      max-width: scale-clamp((375px: 40px, $bp-end-scale: 74px), $units: cqw);
      width: 100%;

      @supports not (container-type: inline-size) {
        margin-left: scale-clamp((375px: 16px, $bp-end-scale: 40px));
        max-height: scale-clamp((375px: 40px, $bp-end-scale: 74px));
        max-width: scale-clamp((375px: 40px, $bp-end-scale: 74px));
      }
    }
  }

  &__text {
    font-size: scale-clamp((375px: 18px, $bp-end-scale: 20px), $units: cqw);
    line-height: 1.4;
    margin-top: scale-clamp((375px: 28px, $bp-end-scale: 56px), $units: cqw);
    max-width: rem(280px);

    @supports not (container-type: inline-size) {
      font-size: scale-clamp((375px: 16px, $bp-end-scale: 20px));
      margin-top: scale-clamp((375px: 28px, $bp-end-scale: 56px));
    }
  }

  &__footer {
    margin-top: scale-clamp((375px: 34px, $bp-end-scale: 55px), $units: cqw);

    @supports not (container-type: inline-size) {
      margin-top: scale-clamp((375px: 34px, $bp-end-scale: 55px));
    }

    .usa-button {
      width: auto;// override USWDS default
    }
  }
}
