//
// Featured Video on Video page
// View name: Featured Video Media
// Display: Block
// --------------------------------------------------

// override spacing
.featured-view-video {
  .card-horz--spotlight .usa-collection__item {
    margin-bottom: 1rem;
  }
  .card-horz__footer {
    margin-top: 1rem;
  }
}