//------------------------------------------------------------------------
// Paragraph Styles
//------------------------------------------------------------------------
// paragraphs are just one kind of muti-value table.
// Please check if other field types will also benefit from the
// styles before making them specific to paragraphs
//------------------------------------------------------------------------

// Expand/Collapse paragraph button
.usa-form .usa-button.paragraphs-icon-button {
  margin-top: 0;
}

// Helptext that we manually added to the paragraph top section
// see "Lead Paragraph" for an example
.paragraph-component-helptext {
  // Sets the helptext in Layout builder to full width
  color: $c-gray-dark;
  grid-column-end: 3;
  grid-column-start: 1;
  margin-bottom: 0.3rem;
}

.paragraphs-dropdown {
  $dropdown-action-spacing: 5px;

  // Paragraph ellipsis menu popup (duplicate and remove)
  &-actions {
    padding: 0 $dropdown-action-spacing $dropdown-action-spacing;
  }

  &-action.button {
    .form-wrapper & {
      @include usa-button;
      @include usa-button--small;
      margin-top: rem($dropdown-action-spacing);
    }
  }
}

// Styling for the remove and duplicate dropdown for paragraphs in Layout Builder
.paragraphs-dropdown.open {
  .paragraphs-dropdown-actions {
    input {
      margin-top: 0.4rem;
      text-align: center;

      &:focus {
        outline: 0.25rem solid #2491ff !important;
        outline-offset: 0 !important;
      }
    }
  }
}

.paragraphs-subform > .usa-accordion {
  padding-right: 30px;
}

.paragraphs-subform:last-child > div > .usa-form-group,
.paragraphs-subform:last-child > div > .usa-checkbox,
.paragraphs-subform:last-child > .field--type-datetime {
  padding-left: 1rem;
}

.paragraphs-subform:last-child > div > fieldset,
.paragraphs-nested > fieldset,
.paragraphs-subform:last-child .field--type-text-long  {
  margin: 1rem;
}

.field--widget-paragraphs {
  td {
    padding: 0;
  }

  .form-type-textarea {
    margin-bottom: 0 !important;
    max-width: none;
  }

  // style the top of paragraphs
  .paragraph-top {
    align-items: center;
    background: #f8f8f8;
    border-bottom: 1px solid $button-gray-med;
    margin: -#{$layout-draggable-spacing-x} 0;
    padding: $layout-draggable-spacing-y;

    .paragraph-type-label {
      font-weight: bold;
    }

    &:only-child {
      border-bottom: 0;
    }
  }
}

//------------------------------------------------------------------------
// Specific Paragraph field overrides
//------------------------------------------------------------------------

// Note: should this be here or should we move it? What about if someone opens this paragraph in the block library, then this will not apply... Does this kind of change need to be in a modules or something because it is really functionality... talk this over

// Contact Cards: hide the 'none' option in the heading level dropdown
.paragraph-type--contact-cards {
  .field--name-field-heading-level option[value="_none"] {
    display: none;
  }
}
