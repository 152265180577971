//------------------------------------------------------------------------
// Radio Buttons and checkboxes
//------------------------------------------------------------------------
.usa-radio {
  background: transparent;
}

//OSSS-30536 workaround for validation tooltip
.usa-radio__input:invalid {
  position: absolute;
  left: 5px;
  right: auto;
  width: 1px;
  height: 1px;
}

// TODO: Can we shorten this selector?
.usa-fieldset :where(.form-radios, .form-checkboxes) {
  > :where(.usa-radio, .usa-checkbox) {
    &:first-child {
      > label {
        margin-top: 0;
      }
    }
  }
}

// Adjust Side-by-side radios
.webform-options-display-side-by-side {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & > div {
    margin: 0;
  }

  & > div > .usa-label {
    margin-top: 0;
  }
}