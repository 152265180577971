//------------------------------------------------------------------------
// Card group overrides
//
// Note: This isn’t listed as a separate component on the USWDS site but
//       is used on the Card demo page
//       https://designsystem.digital.gov/components/card/
//
// Can specify either 2 or 3 columnns using the [data-cols] attribute.
//
//  <div class="usa-card-group" data-cols="2">…</div>
//
// You can also add the [data-cols-stretch] attribute to allow cards in the
// last row to strecth to fill the sapce. This is done using :has() and
// quantity queryselectors. However, :has() is reletively new so unsupported
// browsers will default to the 3-column layout (https://caniuse.com/css-has).
//
//  <div class="usa-card-group" data-cols="2" data-cols-stretch>…</div>
//------------------------------------------------------------------------
.usa-card-group {
  $bp-2-cols: 768px;
  $bp-3-cols: 860px;
  $col-gap: $layout-card-group-col-gap;
  $row-gap-mobile: 26px;
  $row-gap-desktop: 34px;
  display: block;// override USWDS default
  margin: 0;// override USWDS default

  & + & {
    margin-top: rem($row-gap-mobile);
  }

  // 1-col layout
  &:where([data-cols="1"]) {
    display: block;

    & > * {
      &:not(:last-child) {
        margin-bottom: rem($row-gap-mobile);
      }
    }
  }

  // 2-col layout
  // 1. Only use “gap” in wide layout since iOS Safari 14.4
  //    and below doesn’t support it with flexbox (only grid).
  &:where([data-cols="2"]) {
    @include min-width($bp-2-cols) {
      display: flex;
      gap: rem($row-gap-desktop $col-gap);// [1]
      // margin-bottom: rem($row-gap-desktop);
      // margin-top: rem($row-gap-desktop);
    }

    & > * {
      @include max-width($bp-2-cols - 1px) {
        &:not(:last-child) {
          margin-bottom: rem($row-gap-mobile);
        }
      }

      @include min-width($bp-2-cols) {
        flex-basis: calc(50% - #{$col-gap * 0.5});// $col-gap * ((columns - 1) / columns)
        margin-bottom: 0;
      }
    }
  }

  // Auto-stretch cards to fill space
  // Note: Use flex-grow to allow items to expand to fit (requires flex-basis to work)
  // https://css-tricks.com/filling-space-last-row-flexbox/
  &:where([data-cols="2"][data-cols-stretch]) {
    & > * {
      @include min-width($bp-2-cols) {
        flex-grow: 1;
      }
    }
  }

  // 3-col layout (default if no “data-cols” attribute present)
  &:where([data-cols="3"], :not([data-cols])) {
    @include min-width($bp-3-cols) {
      display: flex;
      gap: rem($row-gap-desktop $col-gap);// [1]
      // margin-bottom: rem($row-gap-desktop);
      // margin-top: rem($row-gap-desktop);
    }

    & > * {
      @include max-width($bp-3-cols - 1px) {
        &:not(:last-child) {
          margin-bottom: rem($row-gap-mobile);
        }
      }

      @include min-width($bp-3-cols) {
        margin-bottom: 0;
        flex-basis: calc(33.3% - #{$col-gap * 0.666});// $col-gap * ((columns - 1) / columns)
      }
    }
  }

  // Auto-stretch cards to fill space when “data-cols-stretch” attribute is present
  // Note: Use flex-grow to allow items to expand to fit (requires flex-basis to work)
  // https://css-tricks.com/filling-space-last-row-flexbox/
  &:where([data-cols-stretch]:not([data-cols="2"])) {
    & > * {
      @include min-width($bp-3-cols) {
        flex-grow: 1;
      }
    }
  }
}
