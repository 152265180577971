//------------------------------------------------------------------------
// Copy link button (used on non-landing pages, not a block)
//------------------------------------------------------------------------
// Note: Uses same classes as button_icon pattern
// (i.e. usa-button usa-button--base usa-button--icon)
.copy-link {
  $bp-show-text: 640px;

  @media print {
    display: none !important;
  }

  // Note: Duplicate the selector to increase specificity in order to
  //       override the USWDS styles without resorting to !important.
  &#{&} {
    font-family: $body-copy-font;
    font-size: rem(16px);
    font-weight: normal;
    padding: rem(7px 8px);
    text-transform: none;
    white-space: nowrap;

    @include min-width($bp-show-text) {
      padding-left: rem(12px);
      padding-right: rem(14px);
    }

    .usa-icon {
      margin-right: 0;
    }
  }

  &__text {
    // Add margin if there’s an icon
    &:not(:first-child) {
      margin-left: rem(8px);
    }

    // Note: Button has aria-label so we can use “display: none”
    @include max-width($bp-show-text - 1px) {
      display: none;
    }
  }

  &.copied {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $button-green-light;
    }

    .usa-icon {
      fill: $bg-teal;
    }

    .initial {
      display: none;
    }

    .success {
      display: block;
    }
  }

  .success {
    display: none;
  }
}
