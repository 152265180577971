//------------------------------------------------------------------------
// Exposed Filters General Styles
//------------------------------------------------------------------------

// Views Exposed forms
.views-exposed-form {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;
}

// temp rule to override Speaker field width
.views-exposed-form .form-item-field-person-target-id {
  width: 240px;
}
