//----------------------------------------------------------------------------------------
// Collections Heading and “view all” link above collections
// There are two sections to this file
//    - Views Collection
//    - Component Block Collection
//----------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------
// Views: Collections Styles for heading and “view all” link above collections (optional)
//----------------------------------------------------------------------------------------
.view-block-pattern-collection-item {
  $bp-row: 360px;
  $right-rail-selector: '.node-details-layout__rightrail-region';
  $view-all-gap: 25px;// minimum gap
  $view-all-width: 61px;// need to update manually if styles change

  .block-title {
    margin-bottom: 0;
    margin-top: 0;

    @include min-width($bp-row) {
      float: left;
      max-width: calc(100% - #{$view-all-width});// provide room for “view all”
      padding-right: rem($view-all-gap);
    }

    // Override default right rail heading styles
    #{$right-rail-selector} & {
      border-bottom: 0;// collections already have a border
      padding-bottom: 0;

      @include min-width($layout-rightrail-bp) {
        float: none;
        max-width: none;
        padding-right: 0;
      }
    }

  }

  .more-link {
    @include max-width($bp-row - 1px) {
      margin-top: 1em;
    }

    @include min-width($bp-row) {
      float: right;

      // Allows collections next to each other to align correctly when one has a title and the other does not.
      margin-bottom: rem(4px);
    }

    // Custom styles for when inside of right rail
    #{$right-rail-selector} & {
      margin-top: rem(-2px);

      @include min-width($layout-rightrail-bp) {
        float: none;
        margin-top: 1em;
      }
    }

    a {
      font-size: rem(16px);
      line-height: 1.5;

      #{$right-rail-selector} & {
        line-height: 1;
      }
    }
  }

  // If the collection block does not have a Title displayed or a "View All" link displayed.
  // Remove spacing at the top so it aligns wth other blocks.
  &:not(:has(.block-title)):not(:has(.more-link)) {
    .usa-collection {
      margin-top: 0;
    }
    .usa-collection__item:first-child {
      border-top: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }

  // Wrapper around “usa-collection”
  .collection {
    @include min-width($bp-row) {
      clear: both;
      overflow: hidden;// contain margin from .usa-collection
    }
  }

  // if view has a heading section
  .view-header {
    margin-bottom: 2rem;
  }

}

//----------------------------------------------------------------------------------------
// Component Block: Collections Styles for Block heading above collections (optional)
//----------------------------------------------------------------------------------------
.block[class*="blockcomponent"] {
  // Contains a collection component as the first child
  &:has(.field--type-entity-reference-revisions > .field__item:first-child .collection){

    // remove space on the block title
    .block-title {
      margin-bottom: 0;
      margin-top: 0;
    }

    // And the block has no title displayed
    &:not(:has(.block-title)):not(:has(.more-link)){
      // The first colletion item has extra space removed at the top
      .field--type-entity-reference-revisions .field__item:first-child {
        .usa-collection {
          margin-top: 0;
        }
        .usa-collection__item:first-child {
          border-top: 0;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }

  $bp-row: 360px;

  // Wrapper around “usa-collection”
  .collection {
    @include min-width($bp-row) {
      clear: both;
      overflow: hidden;// contain margin from .usa-collection
    }
  }
}
