//------------------------------------------------------------------------
// Layout Builder Styles
// for off canvas sidebars etc, please see "_off-canvas.scss"
//------------------------------------------------------------------------
.layout-builder__message--overrides .messages {
  background-image: none;// remove extraneous icon
}

// Removes box shadow from the alert component in Layout Builder
.layout-builder-configure-block [data-drupal-messages] .messages--error {
  box-shadow: none;
}


#layout-builder .layout-builder-customizer--links {
  // Positions the loading icon to the left of the customizer links
  .ajax-progress-throbber .throbber {
    margin-left: 4rem;
  }

  // Fixes bug where the accordion disappears when hovering in Layout Builder
  .usa-accordion button {
    display: flex;
  
    &::before{
      display: flex;
    }
  }
}

// Fixes the dropdown when creating a link in Layout Builder modal
.editor-link-dialog .ui-dialog-content {
  overflow: visible;
}