//------------------------------------------------------------------------
// Captcha
//------------------------------------------------------------------------

// Captcha Wrapper fieldset
.captcha-type-challenge--recaptcha {
  margin-top: 1.5rem;
}

.captcha__description {
  margin-bottom: 0;
}

// Actual Captcha
.captcha__element {
  margin-bottom: 0.4rem;
}

// Force captcha to be a bit more responsive
// https://jira.ad.sec.gov/browse/OSSS-28689
@media only screen and (max-width: 400px) {
  .g-recaptcha {
    transform:scale(0.77);
    transform-origin:0 0;
  }
  .captcha__element {
    width: 220px;
  }
}