// -------------------------------------------------------------------------
// Layout Vars
//------------------------------------------------------------------------
// Content well max widths
$layout-max-width: 896px;// Landing page content well
$layout-max-width-narrow: 664px;// Basic page with right rail
$layout-max-width-medium: 818px;// Basic page without right rail
$layout-max-width-wide: 1108px;// Homepage
$layout-max-width-wide--alt: 1044px; //Search page
$layout-max-width-global: 1400px;// Global site max-width

// Horizontal padding vars used by l-padding helper class
$layout-padding-min: 26px;
$layout-padding-max: 36px;
$layout-padding-min-bp: 375px;
$layout-padding-max-bp: 740px;// setting this > 1024px will require refactoring

// Calculate breakpoint when wrappers reach their max-width
$layout-max-width-bp: $layout-max-width + ($layout-padding-max * 2);
$layout-max-width-narrow-bp: $layout-max-width-narrow + ($layout-padding-max * 2);
$layout-max-width-wide-bp: $layout-max-width-wide + ($layout-padding-max * 2);

// Text scaling breakpoint (used by some heading styles)
$layout-text-scale-end-bp: $layout-max-width-narrow-bp;

// Navigation
$layout-nav-bp: 1024px;// switch from mobile to desktop nav

// Header
$layout-header-bp-medium: 740px;
$layout-header-bp-wide: $layout-nav-bp;
$layout-header-padding-horz: 28px;// used in desktop layout

// Side nav (i.e. subpage left navigation)
$layout-sidenav-bp: 1024px;// hidden in mobile
$layout-sidenav-bp-wide: 1100px;
$layout-sidenav-width: 210px;
$layout-sidenav-width-wide: 260px;// used by right rail
$layout-sidenav-horz-padding: 42px;

// Right rail (used on non-landing pages)
$layout-rightrail-bp: $layout-sidenav-bp;// needs to stay in sync for mobile sidenav
$layout-rightrail-width: $layout-sidenav-width-wide;// Formerly 362px (OSSS-23369)
$layout-rightrail-main-horz-padding: 35px;
$layout-rightrail-main-horz-padding-wide: 56px;
$layout-rightrail-border-width: 8px;// displayed in desktop on left viewport edge

// Cards
$layout-card-bp: 640px;// for scaling text and changing box-shadow
$layout-card-horz-padding-min: 20px;
$layout-card-horz-padding-max: 35px;

// Card group
$layout-card-group-col-gap: 28px;

// Pagination
$layout-pagination-buttons-bp: 640px;


// -------------------------------------------------------------------------
// Layout Builder vertical margins
//--------------------------------------------------------------------------
// Layout builder: Section vertical margin
$layout-section-vert-margin-min: 28px;
$layout-section-vert-margin-max: 60px;
// Note: Add fallback value for old browsers that don’t support clamp() since
//       “--layout-section-vert-margin” uses it (defined in _base.scss)
//       https://caniuse.com/css-math-functions
$layout-section-vert-margin-fallback: 40px;
$layout-section-vert-margin: var(--layout-section-vert-margin, $layout-section-vert-margin-fallback);

// Layout builder: Components within a section vertical margin
$layout-default-vert-margin-min: 28px;
$layout-default-vert-margin-max: 40px;
// Note: Add fallback value for old browsers that don’t support clamp() since
//       “--layout-default-vert-margin” uses it (defined in _base.scss)
//       https://caniuse.com/css-math-functions
$layout-default-vert-margin-fallback: 40px;
$layout-default-vert-margin: var(--layout-default-vert-margin, $layout-default-vert-margin-fallback);


// -------------------------------------------------------------------------
// Fields vertical margins
//--------------------------------------------------------------------------
// Field vertical margin
$field-default-vert-margin-min: 28px;
$field-default-vert-margin-max: 40px;
// Note: Add fallback value for old browsers that don’t support clamp() since
//       “--field-default-vert-margin” uses it (defined in _base.scss)
//       https://caniuse.com/css-math-functions
$field-default-vert-margin-fallback: 40px;
$field-default-vert-margin: var(--field-default-vert-margin, $field-default-vert-margin-fallback);

// Small Field vertical margin
$field-small-vert-margin-min: 15px;
$field-small-vert-margin-max: 20px;
// Note: Add fallback value for old browsers that don’t support clamp() since
//       “--field-default-vert-margin” uses it (defined in _base.scss)
//       https://caniuse.com/css-math-functions
$field-small-vert-margin-fallback: 20px;
$field-small-vert-margin: var(--field-small-vert-margin, $field-small-vert-margin-fallback);


// -------------------------------------------------------------------------
// Node Components vertical margins
//--------------------------------------------------------------------------
// Node Components: vertical margin for components on nodes (Content Field etc)
$field-component-vert-margin-min: 28px;
$field-component-vert-margin-max: 40px;
// Note: Add fallback value for old browsers that don’t support clamp() since
//       “--field-component-vert-margin” uses it (defined in _base.scss)
//       https://caniuse.com/css-math-functions
$field-component-vert-margin-fallback: 40px;
$field-component-vert-margin: var(--field-component-vert-margin, $field-component-vert-margin-fallback);

// Drupal form vars
$layout-draggable-spacing-x: 10px;
$layout-draggable-spacing-y: 20px;
