//------------------------------------------------------------------------
// Collection overrides
// https://designsystem.digital.gov/components/collection/
//------------------------------------------------------------------------
.usa-collection {
  $selector: &;
  $bp-end-scale: 1024px;
  $right-rail-selector: '.node-details-layout__rightrail-region';
  $spotlight-card-selector: '.card-horz--spotlight';
  margin-top: rem(26px);

  // Calendar for events
  &__calendar {
    $calendar-width: 78px;

    &-date {
      --color: #{$c-blue};
      font-size: rem(18px);
      font-weight: bold;
      line-height: 1;
      margin-right: rem(14px);
      max-width: rem($calendar-width);
      width: 22%;

      &.is-past {
        --color: #{$c-gray-light};
      }

      &-month,
      &-day {
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: rem($calendar-width * 0.5);
        padding: 0;

        @supports (aspect-ratio: 1) {
          aspect-ratio: 2/1;
          min-height: 0;
        }
      }

      &-month {
        @include font-smoothing;
        background-color: var(--color);
        border-bottom: none;
        border: 1px solid var(--color);
        text-transform: uppercase;
      }

      &-day {
        @include font-smoothing;
        border-color: var(--color);
        color: var(--color);
        font-size: 1.1rem;
      }

      &-time {
        border: 1px solid var(--color);
        border-top: 0;
        color: var(--color);
        display: flex;
        font-size: 0.72rem;
        justify-content: center;
        padding: 0.4rem 0 .3rem;
      }
    }
  }

  &__body {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  // USWDS doesn’t include an event status superheading so we added our own.
  &__status {
    --background-color: #{$bg-gray-light};
    --color: #{$bg-gray};
    background-color: var(--background-color);
    border: 1px solid var(--color);
    color: var(--color);
    font-size: rem(13px);
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 1em;
    padding: rem(4px 4px 3px);
    text-transform: uppercase;

    &.is-cancelled {
      --background-color: #{rgba($c-red-dark, 0.1)};
      --color: #{$c-red-dark};
    }

    &.is-postponed {
      --background-color: #{$bg-orange-light};
      --color: #{$c-orange};
    }
  }

  // USWDS doesn’t include a taxonomy superheading so we added our own.
  &__taxonomy {
    font-size: rem(13px);
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: rem(14px);
    text-transform: uppercase;

    // TODO: The design used black text but this will be clickable so need to
    //       determine if this will pass accessiblity requiements or if we need
    //       to add an underline or icon, or change the color to blue.
    &__link {
      color: $c-black;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__button-item {
    margin-top: 0.5rem;
    float: right;
  }

  // Item
  &__item {
    border-top: 0;// override USWDS default
    margin-bottom: rem(24px);
    max-width: none;// override USWDS default
    padding-top: 0;// override USWDS default

    @media print {
      break-inside: avoid;
    }

    // Note: Only apply the top border on items when inside of “usa-collection”
    //       (e.g. won’t apply to the item in the homepage “spotlight” card).
    #{$selector} & {
      border-top: 1px solid $border-gray;
      padding-top: rem(24px);
    }

    &:first-child {
      margin-top: 0;// override USWDS default
    }

    #{$right-rail-selector} & {
      margin-bottom: rem(8px);
      padding-top: rem(18px);
    }

    // Update styles when used in the Spotlight pattern
    #{$spotlight-card-selector} & {
      @include scale(margin-bottom, (375px: 24px, $bp-end-scale: 29px));
    }
  }

  // Item Heading
  &__heading {
    font-size: rem(18px);
    line-height: 1.2;

    #{$right-rail-selector} & {
      font-size: rem(16px);
    }

    // Update font-size when used in the Spotlight pattern
    #{$spotlight-card-selector} & {
      @include scale(font-size, (375px: 18px, $bp-end-scale: 24px));
    }

    a {
      @include link-underline;
    }
  }

  // Item Description
  &__description {
    font-size: rem(16px);
    line-height: 1.5;

    // Update styles when used in the Spotlight pattern
    #{$spotlight-card-selector} & {
      @include scale(font-size, (375px: 16px, $bp-end-scale: 19px));
      @include scale(margin-top, (375px: 8px, $bp-end-scale: 22px));
    }
  }

  &__meta-item + &__speaker {
    // Use :where() to reduce specificity
    &:where(:not(:first-child)) {
      margin-top: rem(14px);
    }
  }

  // 1. For some reason USWDS add margin-top twice, once on “.usa-collection__meta”
  //    and again on “.usa-collection ul” so we need to use !important.
  &__meta {
    margin-top: rem(12px) !important;// [1]

    &-item {
      font-size: rem(14px);
      line-height: 1.5;
      margin-top: 0;// override USWDS default

      &:where(:not(:first-child)){
        margin-top: rem(4px);
      }

      .date_line-item ~ .date_line-item::before {
        content: ' | ';
      }

      &--url {
        color: green;
        font-size: 0.7rem;
      }
    }
  }
}
