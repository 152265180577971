//------------------------------------------------------------------------
// Global Search (Index Global Search) view custom styling
//------------------------------------------------------------------------

//------------------------------------------------------------------------
//  Layout page and banner
//------------------------------------------------------------------------
.main-content:has(.page-layout-type--layout-heading-banner__full-width) {
  .block-system-breadcrumb-block {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .l-wrap, .block-system-breadcrumb-block {
    max-width: rem($layout-max-width-wide--alt);
  }
}

//------------------------------------------------------------------------
//  Search Page Forms
//------------------------------------------------------------------------
.view-global-search {

  // Form
  .views-exposed-form {
    // Hide the facets in the exposed filters area of the page
    .facets-views-plugin {
      display: none !important;
    }

    .form--inline {
     display: flex;
    }

    .form-item-search {
      max-width: none;
      flex-grow: 1;
    }
  }

  .form-actions {
    clear: left;
    padding-top: 10px;
  }

  .view-header {
    margin-bottom: 0;
  }

}

//------------------------------------------------------------------------
//  Search Page Grid Layout
//------------------------------------------------------------------------
.search-grid {
  display: grid;
  grid-template-columns: 270px auto;
  margin-top: rem(45px);
  gap: rem(30px);

  @container (max-width: 700px) {
    // single column on smaller screens
    grid-template-columns: auto;
  }

  @media print {
    margin-top: rem(20px);
  }
}

//------------------------------------------------------------------------
// Facets blocks in sidebar of search grid
//------------------------------------------------------------------------

:where(.search-grid--side ){

  .facets-views-plugin + .facets-views-plugin {
    margin-top: rem(40px);
  }

  .block-title {
    margin: 0px 0 rem(20px) 0;
    padding: 0px 0 rem(15px) 0;
    font-size: medium;
    border-bottom: 1px solid $border-gray;

  }
}

//------------------------------------------------------------------------
// Facets
//------------------------------------------------------------------------

// Collection filter links are styled like radio buttons
// Note: Also has "usa-radio__label" class applied
.facet-link {
  color: $c-default;
  text-decoration: none;

  // Note: "is-active" class is added via JS in search-facets.js
  &.is-active:before {
    background-color: $link-color;
    box-shadow: 0 0 0 2px $link-color, inset 0 0 0 2px #fff;
  }
}

.facets-views-plugin {
  ul {
    padding-left: 0;
    margin: 10px 0;
  }

  .facet-item {
    list-style-type: none;
  }
}

// facet items that have a checkbox
.facet-item:has( > input[type=checkbox]) {
  display: grid;
  column-gap: 5px;
  grid-template-columns: [checkbox-start] 20px [label-start] auto [end];
  align-items: baseline;
  margin-left: 0;

  & > .facets-widget- {
    grid-column: label-start/end;
  }
}

// Top-level facets
ul[data-drupal-facet-id] > li > label {
  color: $link-color;

  .facet-item__value {
    font-weight: bold;
  }
}

ul[data-drupal-facet-id] :disabled,
ul[data-drupal-facet-id] :disabled + label {
  color: usa.color("disabled-dark") !important;
  cursor: wait;
}


// Checkbox facets
.facets-checkbox {
  // Add loading indicator (uses "autorenew" Material icon via USWDS)
  &.is-loading + label {
    &:after {
      animation:spin 1.4s linear 0s infinite normal none;
      background-image: url("data:image/svg+xml,%3Csvg fill=%27%2373B3E7%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 22%27 preserveAspectRatio=%27xMidYMid meet%27%3E%3Cpath d=%27M8 5v3l4-4-4-4v3a8 8 0 0 0-6.76 12.26L2.7 13.8A6 6 0 0 1 8 5Zm6.76 1.74L13.3 8.2A6 6 0 0 1 8 17v-3l-4 4 4 4v-3a8 8 0 0 0 6.76-12.26Z%27/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 1em;
      margin-left: 4px;
      margin-top: 0.25em;
      position: absolute;
      width: 1em;
    }
  }
}

//------------------------------------------------------------------------
// EDGAR | Filings Search Results on the /Search
//------------------------------------------------------------------------
.integrated-search {
  // Box
  &-section__edgar{
    background-color: usa.color('blue-5');
    padding: rem(35px) rem(39px);
    padding: 35px 39px;
    margin-bottom: rem(20px);

    // Hide CIK number when box is narrow
    @container (max-width: 520px) {
      .integrated-search-hint-cik {
        display: none;
      }
    }

    @media print {
      border: 1px solid currentColor;
    }
  }

  // Heading spacing
  &-EDGAR {
    display: inline-block;
    margin-right: 5px;
  }

  &-hint {
    th,
    td {
      background-color: transparent;// override USWDS default
      border: 0;// override USWDS default
      padding: rem(2px) 0;// override USWDS default

      @media print {
        display: block;
        text-align: left;
      }
    }

    // CIK number <td>
    &-cik {
      text-align: right;

      @media print {
        margin-bottom: 10px;
      }
    }
  }

  &-edgar-full-text {
    font-weight: bold;

    // Magnifying glass icon
    &:before {
      background-color: currentColor;
      content: '';
      display: inline-block;
      height: rem(20px);
      margin-right: rem(10px);
      mask-image: url('/themes/custom/uswds_sec/assets/img/usa-icons/search.svg');
      position: relative;
      top: rem(4px);
      width: rem(20px);
      @media print {
        content: none;
      }
    }
  }

}

//------------------------------------------------------------------------
// EDGAR | Filings Search Results on the /Search
//------------------------------------------------------------------------

.federal_register_results {
  &__title {
    margin-top: 60px;
  }
  &__link {
    margin-top: 20px;
    display: block;
    padding-top: 20px;
    border-top: 1px solid $border-gray;
  }
}

//------------------------------------------------------------------------
// Collection Results
//------------------------------------------------------------------------
.search-grid--main .usa-collection {
  margin-top: 0;
}

//------------------------------------------------------------------------
// Results Count
//------------------------------------------------------------------------
.view-global-search .view-footer {
  text-align: right;
}

