//------------------------------------------------------------------------
// Default page title block
//
// See /templates/patterns/page_header/ for landing page title block
//------------------------------------------------------------------------
// Note: We want the text to align with the main column in desktop layout
.page-title {
  margin-bottom: rem(28px);

  &__heading {
    @include page-title;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__above-title {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(21px);
  }

  &___page-category {
    color: $c-default;
    flex-grow: 1;
    font-family: $gotham-font-family;
    font-weight: $gotham-medium;
    text-transform: uppercase;

    a {
      color: $c-default;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    min-height: 41px; //Added to stop page repaint when there is no page category. This is the calculated height of the button

    > * {
      margin-left: rem(10px);

      @include min-width(640px) {
        margin-left: rem(17px);
      }
    }
  }
}
