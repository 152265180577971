$embedMediaLarge: "(min-width: 720px)";
$embedMediaSmall: "(max-width: 719px)";

.align-center, .align-left, .align-right {
  width: fit-content;
  margin: 15px auto;
  float: none;
  clear: both;
}

@mixin floatMedia() {
  .align-left, .align-right {
    margin: 0;
    clear: none;
    max-width: 65%;
    margin-top: 4px;
    margin-bottom: 4px;
    &.media--type-image-media{
      margin-bottom: 0;
    }
  }

  .align-left {
    float: left;
    margin: 4px 0;
    margin-right: 15px;
  }

  .align-right {
    float: right;
    margin: 4px 0;
    margin-left: 10px;
  }

  // fix heading next to floated images so they align to the top
  .align-center, .align-left, .align-right {
    & + {
      h2, h3, h4, h5, h6 {
        margin-top: 0;
      }
    }
  }
}

// Float images on larger screens
@media #{$embedMediaLarge} {
  @include floatMedia;
}

// Float images in ckeditor while editing
:where(.cke_editable) {
  @include floatMedia;
}

.media--type-image-media, .caption-drupal-media:has(.media--type-image-media) {
  // Temp leaving code in but commented out. If need to add a image border option on images.
  // &.align-center, &.align-left, &.align-right {
  //   border: 1px solid #e3e3e3;
  //   padding: 5px;
  // }
  &.caption:not(.align-center, .align-left, .align-right) {
    margin: 0;
  }
  figcaption {
    font-style: italic;
    margin-top: 5px;
    font-size: .8rem;
    margin-bottom: 5px;
    color: #71767a;
  }
}

// Fix lists bullet points next to floated left images
:where(.usa-prose), :where(.cke_editable) {
  &:has(.media--type-image-media):has(.media--type-image-media) {
    ol, ul {
      padding-left: 0em;
      position: relative;
      left: 3ch;
    }
  }
}
