//------------------------------------------------------------------------
// Event node custom styles
//------------------------------------------------------------------------
.node--type-event {
  $bp-wide: 500px;

  // set smaller spacing for items at the top of the page
  .field--name-field-location-type,
  .field--name-field-sec-event-date,
  .field--name-field-event-speaker {
    margin-bottom: $field-small-vert-margin;
  }

  // add lines between person cards
  .field--name-field-contact-cards > {
    .field__item:not(:last-child) {
      margin-bottom: rem(30px);
      border-bottom: 1px solid $border-gray;
      padding-bottom: rem(30px);
    }
  }

  // add a line between two contact fields if both have values
  .field--name-field-contact-cards + .field--name-field-contact {
    margin-top: rem(30px);
    border-top: 1px solid $border-gray;
    padding-top: rem(30px);
  }

  // fix alignment because of shape of icon
  .icon-text--location {
    margin-left: -3px;
  }
}

// Sessions listed on an event
.group-sessions {
  margin-bottom: $field-default-vert-margin;
  margin-top: $field-default-vert-margin;

  h2 {
    font-size: rem(20px);
    margin-bottom: rem(10px);
    font-family: $gotham-font-family;
  }
}

.event-session {
  margin-bottom: rem(10px);

  border-left: 3px solid $c-blue;
  padding: 5px 15px;

  &__title {
    font-weight: bold;
  }

  .icon-text-row {
    margin-top: (5px);
  }
}

