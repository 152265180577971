//------------------------------------------------------------------------
// Image Component
//------------------------------------------------------------------------
.media--view-mode-component-block-image .field--name-field-media-caption {
  font-size: 1rem;
  text-align: left;
  line-height: 1.5rem;
  font-weight: 300;
  margin-top: 0.75rem;
}
