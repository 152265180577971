//------------------------------------------------------------------------
// Multiple Table - .mutivalue-form-item table
// Fields that allow more than one value to be added in a table
// Entity reference field that allow more than one reference
//------------------------------------------------------------------------

// Show/Hide Row Weight Button
.tabledrag-toggle-weight-wrapper .tabledrag-toggle-weight {
  @include usa-button;
  @include usa-button--small;
}

// Actual table
.field-multiple-table {
  margin-bottom: 0px;
  margin-top: 10px;
  max-width: none;
  width: 100%;

  td > .usa-form-group {
    margin: 0;
  }

  //------------------------------------------------------------------------
  // Column that contains the drag icon ✥
  //------------------------------------------------------------------------
  .field-multiple-drag {
    padding: 0;
    width: 30px;

    .tabledrag-handle {
      margin-left: 0;
      position: relative;

      .handle {
        margin-left: 0;
        margin-right: 0;
        // padding: 0.42em 0.25em;
        position: relative;
        // Centers the arrow for the component in Layout Builder
        right: -0.4rem;
        top: 0.35rem;

        &:hover,
        &:focus {
          transform: none;
        }
      }
    }

    //------------------------------------------------------------------------
    // Column that contains the fields for content
    //------------------------------------------------------------------------
    + td {
      padding: $layout-draggable-spacing-x $layout-draggable-spacing-y;
    }
  }

  //------------------------------------------------------------------------
  // Column that contains the Order <select> for table drag row weights
  //------------------------------------------------------------------------
  td.delta-order {
    padding: rem(10px);
    width: rem(80px);

    select {
      margin-bottom: 0 !important;
      // Adjusts the margin for the select dropdown when a user is re-ordering via weights in layout builder
      margin-left: 0.35rem;
    }
  }
}

//------------------------------------------------------------------------
// New Item Buttons (Add Another Item, Add Accordion Section, etc)
//------------------------------------------------------------------------
// Wrapper for buttons to add a new paragraph to the field
.mutivalue-form-item-buttons {
  margin-top: 10px;

  .field-add-more-submit {
    @include usa-button--small;
    margin: 0.5rem 0.25rem 0.5rem 1rem;
  }
}
