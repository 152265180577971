// Animation helper mixin with sensible defaults
@mixin animation(
  $name,
  $duration: 1s,
  $timing-fn: ease,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  @media (prefers-reduced-motion: no-preference) {
    // animation-delay: $delay;
    // animation-direction: $direction;
    // animation-duration: $duration;
    // animation-fill-mode: $fill;
    // animation-iteration-count: $count;
    // animation-name: $name;
    // animation-timing-function: $timing-fn;
    animation: $name $duration $timing-fn $delay $count $direction $fill;
  }
}


// Slide down (used by sticky header)
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@mixin slideDown(
  $duration: 500ms,
  $timing-fn: ease,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $fill: none
) {
  @include animation(slide-down, $duration, $timing-fn, $delay, $count, $direction, $fill);
}

// Slide up (used by sticky header)
@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@mixin slideUp(
  $duration: 200ms,
  $timing-fn: ease-in,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $fill: none
) {
  @include animation(slide-up, $duration, $timing-fn, $delay, $count, $direction, $fill);
}

// Spin (used by search facet loading indicator)
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@mixin spin(
  $duration: 1.4s,
  $timing-fn: linear,
  $delay: 0s,
  $count: infinite,
  $direction: normal,
  $fill: none
) {
  @include animation(spin, $duration, $timing-fn, $delay, $count, $direction, $fill);
}
