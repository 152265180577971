//------------------------------------------------------------------------
// Region Highlighted
//------------------------------------------------------------------------
.region-highlighted {
  .usa-alert .usa-alert__body {
    max-width: rem($layout-max-width-global);
  }

  .views-row + .views-row,
  [data-drupal-messages] + .view-block-pattern-alert,
  .view-block-pattern-alert + .view-block-pattern-alert,
  .usa-alert + .usa-alert {
    margin-top: 3px;
  }
}