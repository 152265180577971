// Layout helper classes
// Note: Intended to make it easier to create page template
//       layouts without requiring custom CSS for each one.
.l {
  // Default horizontal padding (indents content from viewport edge)
  &-padding {
    @include default-padding;
  }

  // Default vertical margin above and below the main content well
  &-margin {
    @include default-page-bottom;
    @include default-page-top;

    &-top {
      @include default-page-top;
    }

    &-bottom {
      @include default-page-bottom;
    }
  }

  &-wrap {
    max-width: rem($layout-max-width);

    &--narrow {
      max-width: rem($layout-max-width-narrow);
    }

    &--wide {
      max-width: rem($layout-max-width-wide);
    }

    // Center wrappers
    // Note: We’re using :where() to reduce specificity so we can easily override.
    //       It’s supported in Safari 14+ https://caniuse.com/mdn-css_selectors_where
    // Note: Just checking for [class*="l-wrap"] can result in false positives
    //       (e.g. “Modal-wrap” would match) so we need two attribute selectors.
    @at-root [class^="l-wrap"], [class*=" l-wrap"] {
      margin-left: auto;
      margin-right: auto;

      @media print {
        max-width: none !important;
      }
    }
  }

  // Make element 100vw width regardless of parent width
  // NOTE: These styles only work when the parent wrapper is centered.
  &-fullwidth {
    @include layout-fullwidth;

    // Disable max-width on media elements (from CSS reset)
    &:is(canvas, img, picture, video) {
      max-width: none;
    }
  }

  // Classes to show/hide content at side nav breakpoint
  &-show-sidenav {
    &-mobile {
      @include min-width($layout-sidenav-bp) {
        display: none;
      }
    }
  }

  // Sidenav layout (used on landing pages)
  // Note: The markup order doesn’t matter since we’re using flexbox
  &-sidenav {
    @include layout-sidenav-wrap;

    &-main {
      @include layout-sidenav-main;
    }

    &-aside {
      @include layout-sidenav-aside;
    }
  }

  // Right rail layout (used on default template)
  // TODO: We’re currently using the exisitng column classes from the old site
  //       but ideally these will be refactored to be consistent on the new site.
  &-sidebar {
    @include layout-rightrail-wrap;

    // Main content
    &-main {
      @include layout-rightrail-main;
    }

    &-aside {
      @include layout-rightrail-aside;
    }
  }

}
