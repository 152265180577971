//------------------------------------------------------------------------
// Process list overrides
// http://localhost:3000/sec-styleguide/usa-process-list
//------------------------------------------------------------------------
// 1. Undo USWDS style for accessiblity
// https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
.usa-process-list {
  font-family: $body-copy-font;// override USWDS default
  font-size: inherit;
  list-style: revert;// [1]

  &__item {
    font-size: inherit;

    &::marker {
      color: transparent;
    }
  }

  &__heading {
    @include h3;
    font-family: inherit;
  }
}
