//------------------------------------------------------------------------
// Contact card
// See /templates/patterns/card/_card.scss for default card styles
//------------------------------------------------------------------------
.card-contact {
  $bp-end-scale: 460px;
  @include card-box-shadow;
  @include scale(padding-bottom, (375px: 30px, $bp-end-scale: 36px));
  @include scale(padding-left padding-right, (375px: 20px, $bp-end-scale: 40px));
  @include scale(padding-top, (375px: 22px, $bp-end-scale: 34px));
  background-color: $bg-default;

  @media print {
    break-inside: avoid;
  }

  &__name {
    @include scale(font-size, (375px: 15px, $bp-end-scale: 16px));
    font-size: rem(15px);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: rem(18px);

    // Optional link (currently using “.usa-link” class)
    // &__link {}
  }

  // Description text
  &__text {
    font-size: rem(15px);
    line-height: 1.5;

    & + & {
      margin-top: 1em;
    }
  }

  // Used for email and phone links
  // Note: These styles are mostly the same as .card-person, could refactor
  &__link {
    align-items: center;
    display: flex;
    margin-top: rem(12px);
    width: fit-content;// otherwise “flex” will cause it to be 100% wide

    // adjust this to only apply to first element
    &:first-child {
      margin-top: 0;
    }

    // Note: “.usa-icon” includes “height: 1em; width: 1em;” from USWDS
    // Note: Since we can’t get each individual icon’s viewBox dimensions
    //       in the template, the size of the SVG element won’t match the
    //       use element, so the styles may not match Figma _exactly_.
    .usa-icon {
      fill: $c-blue;
      flex-shrink: 0;
      height: 100%;
      margin-right: rem(8px);
      max-height: rem(27px);
      max-width: rem(27px);
      width: 100%;
    }
  }

  &__custom_text {
    color: $c-default;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__address {

    .address {
      line-height: 1.8;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
