//------------------------------------------------------------------------
// Speaker List for Events
//------------------------------------------------------------------------

.speaker-list {
  line-height: 1.5;
  ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 10px;
    @media print {
      list-style: disc;
      padding-left: 3ch;
    }
  }
  ul li{
    @include usa.at-media("tablet") {
      display: inline-flex;
    }
    display: flex;
    margin-right: 1.2rem;
    white-space: nowrap;

    @media print {
      display: list-item;
    }

    &:before {
      background-color: $bg-yellow;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 10px;
      margin-right: 0.6rem;
      width: 10px;
      min-width: 10px;
      transform: translateY(60%);
      @media print {
        content: none;
      }
    }
    & .paragraph, & > div {
      display: inline-block;
      white-space: normal;
      @media print {
        display: block;
      }
    }
  }

}
