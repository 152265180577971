/// Break long words, hyphenate if possible (requires `lang` attribute)
/// @group Main
/// @link https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
/// @link http://kenneth.io/blog/2012/03/04/word-wrapping-hypernation-using-css/
/// @link https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/
/// @link http://caniuse.com/#feat=css-hyphens
/// @link http://caniuse.com/#feat=wordwrap
/// @ignore Demo http://jsbin.com/fubor
/// @ignore - Hyphenation not currently suported in Chrome, Opera, Android, or IE9-
/// @ignore - Hyphenation depends on dictionary, so requires lang attribute be set
/// @ignore - Setting "word-break: break-all" will disable hyphens in Firefox and IE10+
@mixin break-word {
  hyphens: auto;// requires lang attribute be set on target element or ancestor
  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
}
