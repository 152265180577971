//------------------------------------------------------------------------
// Custom styles for Drupal section multi-column layouts
//
// Markup example:
//
//   <div class="layout layout--twocol-section layout--twocol-section--50-50">
//     <div class="layout__region layout__region--first">…</div>
//     <div class="layout__region layout__region--first">…</div>
//   </div>
//------------------------------------------------------------------------
.layout {
  $selector: &;
  $col-gap: 28px;
  // Note: We’re not using gap since support with flexbox isn’t quite good
  //       enough yet for something this important. Once we no longer need
  //       to worry about Safari 14.0 and iOS Safari 14.4 we can switch.
  //       https://caniuse.com/flexbox-gap
  --layout-col-gap: 0;
  --layout-row-gap: #{$layout-section-vert-margin};// only used when stacked
  margin-left: calc(var(--layout-col-gap) * -1);

  // Use :where() to keep specificity low in case we need to override elsewhere
  :where(& + &) {
    margin-top: $layout-section-vert-margin;
  }

  // Set column widths
  &__region--width-75 {
    flex: 1 1 75%;
  }

  // We need both selectors because Drupal rounds up
  &__region--width-66,
  &__region--width-67 {
    flex: 1 1 66.6%;
  }

  &__region--width-50 {
    flex: 1 1 50%;
  }

  // We need both selectors because Drupal rounds up
  &__region--width-33,
  &__region--width-34 {
    flex: 1 0 33.3%;

    // Set min-width to prevent column form being too small in narrow viewports
    @include max-width($layout-max-width-bp - 1px) {
      min-width: rem(260px);

      // Disable min-width for equal-column rows
      .layout--threecol-section--33-34-33 & {
        min-width: 0;
      }
    }
  }

  &__region--width-25 {
    flex: 1 0 25%;

    // Set min-width to prevent column form being too small in narrow viewports
    @include max-width($layout-max-width-bp - 1px) {
      min-width: rem(217px);

      // Uncomment if supportig 4-col layout
      // Disable min-width for equal-column rows
      // .layout--fourcol-section--25-25-25-25 & {
      //   min-width: 0;
      // }
    }
  }

  // Column wrapper styles
  // Note: This will override any parent containers (e.g. main content well)
  &__region {
    container-type: inline-size;
    margin-left: var(--layout-col-gap);

    // Add margin between columns when stacked
    // Note: --layout-row-gap is reset to 0 when in row layout
    & + & {
      margin-top: var(--layout-row-gap);
    }

    // Allow narrow columns to shrink when they’re all the same width
    // Uncomment if supportig 4-col layout
    // .layout--fourcol-section--25-25-25-25 &,
    .layout--threecol-section--33-34-33 & {
      flex-shrink: 1;
    }
  }

  // Row wrapper styles
  //
  // Note: We’re using container queries so we don’t need to define separate
  //       breakpoints for every page layout. Older browser will fallback
  //       to regular media queries and will not adjust based on the layout.
  //
  // Note: Set breakpoints based on largest column, aiming to stay within
  //       the USWDS reccomendation of 45–90 characters per line.
  //       https://designsystem.digital.gov/components/typography/#measure-line-length-2
  &--twocol-section {
    &--50-50 {
      $bp-50-50: 710px;

      @container (min-width: #{rem($bp-50-50)}) {
        --layout-col-gap: #{$col-gap};
        --layout-row-gap: 0;
        display: flex;
      }

      // Fallback styles for browsers that don’t support container-queries
      // https://caniuse.com/css-container-queries
      @supports not (container-type: inline-size) {
        @include min-width($bp-50-50 + $layout-padding-max) {
          --layout-col-gap: #{$col-gap};
          --layout-row-gap: 0;
          display: flex;
        }
      }
    }

    &--33-67,
    &--67-33 {
      $bp-33-67: 630px;

      @container (min-width: #{rem($bp-33-67)}) {
        --layout-col-gap: #{$col-gap};
        --layout-row-gap: 0;
        display: flex;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-33-67 + $layout-padding-max) {
          --layout-col-gap: #{$col-gap};
          --layout-row-gap: 0;
          display: flex;
        }
      }
    }

    &--25-75,
    &--75-25 {
      $bp-25-75: 590px;

      @container (min-width: #{rem($bp-25-75)}) {
        --layout-col-gap: #{$col-gap};
        --layout-row-gap: 0;
        display: flex;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-25-75 + $layout-padding-max) {
          --layout-col-gap: #{$col-gap};
          --layout-row-gap: 0;
          display: flex;
        }
      }
    }
  }

  &--threecol-section {
    &--33-34-33 {
      $bp-33-34-33: 812px;

      @container (min-width: #{rem($bp-33-34-33)}) {
        --layout-col-gap: #{$col-gap};
        --layout-row-gap: 0;
        display: flex;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-33-34-33 + $layout-padding-max) {
          --layout-col-gap: #{$col-gap};
          --layout-row-gap: 0;
          display: flex;
        }
      }
    }

    &--25-50-25,
    &--25-25-50,
    &--50-25-25 {
      $bp-25-50-25: 830px;

      @container (min-width: #{rem($bp-25-50-25)}) {
        --layout-col-gap: #{$col-gap};
        --layout-row-gap: 0;
        display: flex;
      }

      @supports not (container-type: inline-size) {
        @include min-width($bp-25-50-25 + $layout-padding-max) {
          --layout-col-gap: #{$col-gap};
          --layout-row-gap: 0;
          display: flex;
        }
      }
    }
  }

  // Uncomment if supportig 4-col layout
  // &--fourcol-section {
  //   &--25-25-25-25 {
  //     $bp-25-25-25-25: 880px;
  //
  //     @container (min-width: #{rem($bp-25-25-25-25)}) {
  //       --layout-col-gap: #{$col-gap};
  //       --layout-row-gap: 0;
  //       display: flex;
  //     }
  //
  //     @supports not (container-type: inline-size) {
  //       @include min-width($bp-25-25-25-25 + $layout-padding-max) {
  //         --layout-col-gap: #{$col-gap};
  //         --layout-row-gap: 0;
  //         display: flex;
  //       }
  //     }
  //   }
  // }

}
