//------------------------------------------------------------------------
// UI Dialog
// Clean up the jQuery UI Dialog to fit in with the new uswds theme
//------------------------------------------------------------------------

.ui-dialog .ui-dialog-buttonpane {
  background-color: #fff;
  border-top: 0;
}

.ui-dialog .ui-dialog-titlebar {
  font-size: 1.2rem;
  background: #fff;
}

.ui-dialog.ui-widget.ui-widget-content {
  border: none;
}

.ui-dialog-buttonpane .usa-button.ui-button {
  &:hover, &:active, &:focus {
    font-weight: bold;
    border: none;
  }
}

