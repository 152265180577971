//------------------------------------------------------------------------
// Table overrides
// https://designsystem.digital.gov/components/table/
//------------------------------------------------------------------------
.usa-table {
  &-container {
    &--scrollable {
      // 1. USWDS sets it to “nowrap” which results in tables containing
      //    long links having very wide columns (e.g. Press Releases)
      word-break: keep-all;
      td {
        white-space: normal;// [1]
      }
    }
  }

  &--compact {
    tbody {
      font-size: .9rem;
    }
  }
}
