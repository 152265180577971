//------------------------------------------------------------------------
// Webforms
// Basic styles for webforms
//------------------------------------------------------------------------
// Preview page
.webform-submission-data {
  .usa-form-group {
    margin-top: 10px;
  }

  .usa-label, label {
    font-weight: bold;
  }
  @include sec-prose;
}

.webform-submission-edgar-feedback-form #edit-question-3-container .fieldset__wrapper {
  .usa-checkbox label{
    margin-top: 0;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }

  .form-item-other-reasons label {
    margin-bottom: 0;
  }
}

.webform-submission-form {
  .error.form-item--error-message {
    color: $c-red;
  }
}

// International Telephone Input
.iti {
  margin-top: .5rem;
}

// Step indicator
.usa-step-indicator__segment-label [role="link"] {
  text-decoration: underline;
  cursor: pointer;
  &:hover, &:focus {
    color: #005ea2;
  }
}

.usa-step-indicator--counters .usa-step-indicator__segment:before,
.usa-step-indicator--counters-sm .usa-step-indicator__segment:before {
  line-height: 1.1;
}

.path-webform .file {
  background: none;
  padding: 0;
  display: inline;
  overflow-wrap: anywhere;
  font-style: italic;
}

// Webform uploaded files
.webform-document-file {
  .usa-checkbox__label {
    max-width: none;
  }
}

// Do not print file path for uploaded files
@media print {
  .webform-element-type-webform-document-file, .webform-document-file {
    a:after {
      content: '' !important;
      display: none;
    }
  }
}