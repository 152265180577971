//------------------------------------------------------------------------
// Lede text (a.k.a. intro)
//------------------------------------------------------------------------
.lead-paragraph {
  @include scale(font-size, (375px: 19px, 768px: 26px));
  font-weight: usa.$theme-font-weight-light;
  line-height: 1.25;
  margin-bottom: rem(28px);

  // Slightly smaller on landing pages
  .node--type-landing-page & {
    @include scale(font-size, (375px: 16px, 768px: 19px));
    font-weight: normal;
    line-height: 1.5;
  }

  p + p {
    margin-top: 1em;
  }
}
